import React from "react";

import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    MenuItem, 
    Paper, 
    TextField, 
    withStyles 
} from "@material-ui/core";
import Lens from "@material-ui/icons/Lens";

import secaoStyle from "../../assets/css/layout/secaoStyle";

import Button from "../../components/CustomButtons/Button";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CertidaoNegativa from "./CertidaoNegativa";
import CertidaoNegativaIptu from "../Iptu/Movimento/CertidaoNegativaIptu";
import Table from "../../components/Table/Table";
import TablePagination from "@material-ui/core/TablePagination"
import { get, print, search } from "../../util/certidaonegativa";
import { hideWaiting, showNotification, showWaiting } from "../../util/Constants";

// Icons
import Print from "@material-ui/icons/Print"; 
import Search from "../../components/Search";
import LoadingContent from "../../components/LoadingContent";


class CertidoesNegativa extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = {
            isModalOpem: false,
            tipo_certidao_id: 0,
            tipos_certidao: [
                {id: 0, descricao: "Selecione o tipo de certidão."},
                {id: 1, descricao: "Certidão negativa de débitos"},
                {id: 2, descricao: "Certidão negativa de IPTU"},
            ],
            certidoes: [],
            errors: [],
            isSearch:false,
            searchText: "",
            field: 0,
            page: 0,
            pageCount: 0,
            perPage: 0
        }
        this.handleChange = this.handleChange.bind(this);
        this.getData = this.getData.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.printCertidao = this.printCertidao.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.filter = this.filter.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
    }

    componentWillMount() {
        this.getData()
    }

    getData(page)
    {
        // showWaiting();
        this.setState({isLoading:true,isSearch:true})
        let response = (error, data) => {
            this.setState({isLoading:false,isSearch:false})
            // hideWaiting();
            if(error)
            {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                return;
            }

            this.setState({
                certidoes: data.data,
                pageCount: data.total,
                perPage: data.per_page
            })
        }

        get(page, response);
    }

    printCertidao(key)
    {
        showWaiting();
        
        let response = (error, data) => {
            hideWaiting();
            if(error)
            {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                return;
            }
            var url = URL.createObjectURL(data)
            let tempLink = document.createElement("a")
            tempLink.setAttribute("target", "_blank")
            tempLink.setAttribute("href", `${url}`)
            tempLink.setAttribute("style", "display:none;")
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
        }

        print(this.state.certidoes[key].id, response);
    }

    handleChange(event)
    {
        const {name, value} = event.target;
        const errors = this.state.errors;

        this.setState({
            [name]: value,
            errors
        });
    }

    toggleModal()
    {
        this.setState({
            isModalOpem: !this.state.isModalOpem
        });
    }


    // funções de busca 

    handleChangePage = (event, page) => {
        var pagina;
        pagina = page + 1;
        this.setState({page: page})
        if(page < 0){
            return;
        }
        if (this.state.searchText.toString().trim() != "") {
            this.filter({target: {value: this.state.searchText}}, pagina, page)
            return;
        }

        this.getData(pagina)
    };
    
    handleFieldChange(value) 
    {
        this.setState({
            field: value
        })
    }

    filter(event, pagina) 
    {
        let searchText = event.target.value
        var newState ={
          isLoading:true,
          searchText: searchText,
          searchActive: searchText !== ""
        }
        if(pagina == undefined){
          newState['page'] = 0;
        }
        this.setState(newState,
          () => {
            if (this.state.searchActive) {
              let filtersCheckbox = this.state.filterField || ""
              search(searchText, filtersCheckbox, undefined, (error, data) => {
                this.setState({isLoading:false})
                if (error) {
                  showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                  )
                } else {
                  if (this.state.searchText !== "") {
                    this.setState({
                      certidoes: data.data,
                      pageCount: data.total,
                      perPage: data.per_page,
                    })
                  }
                }
              }, pagina)
            } else {
              this.getData()
            }
          }
        )
      }

    render()
    {
        const { classes } = this.props;
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código da certidão" },
            { value: "ano", label: "Ano" },
            { value: "n_certidao", label: "Número da certidão" },
            { value: "contribuinte_id", label: "Código do contribuinte" },
            {
              value: "contribuinte_nome_razao_social",
              label: "Nome/Razão social do contribuinte"
            }
          ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Certidões Negativa</h2>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <Button onClick={this.toggleModal} color="success" round block>
                                    Gerar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Search
                            label="Pesquise aqui"
                            value={this.state.filterField}
                            onChangeText={this.filter}
                            onChangeFields={this.handleChange}
                            fields={fields}
                        />
                        <Grid
                            className={classes.legend}
                            container
                            direction="row"
                            style={{ marginBottom: "2%" }}
                        >
                            <Lens className={classes.dot + " " + classes.dotWarning} />
                            Vencida
                        </Grid>
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) => {
                                        if (this.state.certidoes[key].vencido) {
                                            return classes.warningTableRow
                                        } else {
                                            return ""
                                        }
                                    }} 
                                    tableHeaderColor="primary"
                                    certidoesNegativas={this.state.certidoes}
                                    filter={true}
                                    tableHead={["#", "ano", "N° certidão", "Tipo", "Contribuinte", "Data de Emissão", "Válido"]}
                                    tableData={(this.state.certidoes || []).map((option, key) =>{
                                        return [
                                            option.id,
                                            option.ano,
                                            option.n_certidao,
                                            option.tipo.descricao,
                                            option.contribuinte.nome_razao_social,
                                            option.data_de_emissao,
                                            option.validade,
                                            key
                                        ]
                                    })}
                                    actions={[
                                        {
                                        title: "Imprimir certidão",
                                        icon: (
                                            <Print
                                            className={
                                                classes.tableActionButtonIcon + " " + classes.edit
                                            }
                                            />
                                        ),
                                        onClick: key => this.printCertidao(key)
                                        }
                                    ]}
                                />
                                { (
                                    <TablePagination
                                        component="div"
                                        rowsPerPageOptions={[]}
                                        count={this.state.pageCount}
                                        rowsPerPage={this.state.perPage?this.state.perPage:0}
                                        page={this.state.page?this.state.page:0}
                                        backIconButtonProps={
                                        this.state.isSearch ?
                                            {"aria-label": "Previous Page", disabled: this.state.isSearch} :
                                            {"aria-label": "Previous Page"}}
                                        nextIconButtonProps={this.state.isSearch ? {
                                        "aria-label": "Next Page",
                                        disabled: this.state.isSearch
                                        } : {
                                        "aria-label": "Next Page",
                                        }}
                                        SelectProps={{
                                        native: true
                                        }}

                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                )}
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>

                <Dialog
                    open={this.state.isModalOpem}
                    onClose={this.toggleModal}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="lg"
                    style={{ zIndex: "6" }}
                >
                    <DialogTitle id="form-dialog-title">Gerar Certidão Negativa</DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    required
                                    name="tipo_certidao_id"
                                    label="Tipos de certidão"
                                    fullWidth
                                    value={this.state.tipo_certidao_id}
                                    onChange={this.handleChange}
                                    error={this.state.errors.tipo_certidao_id}
                                    helperText={this.state.errors.tipo_certidao_id}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {this.state.tipos_certidao.map((option, key) => (
                                    <MenuItem key={key} value={option.id}>
                                        {option.descricao}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        {this.state.tipo_certidao_id === 1 && <CertidaoNegativa />}
                        {this.state.tipo_certidao_id === 2 && <CertidaoNegativaIptu />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleModal} color="danger">
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        );
    }
}
// CertidoesNegativa = withRouter(CertidoesNegativa)
export default withStyles(secaoStyle)(CertidoesNegativa);