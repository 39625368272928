import React from "react"
import { Grid } from "@material-ui/core";
import numeral from "../../util/numeral";
export default class TemplateImovel extends React.PureComponent {
    data
    constructor(props) {
        super(props)

        //let base =  atob(this.props.location.search.toString().replace("?doc=",""))
        this.data = JSON.parse(localStorage.getItem("doc"))
        console.log(this.props)

    }

    componentDidMount() {
        window.print();
    }

    chunk(arr, v) {
        let i = 0;
        let c = v;
        let retorno = [];
        let cont = 0
        while (i < arr.length) {
            console.log(arr.slice(i, c));
            retorno[cont] = arr.slice(i, c)
            i += v;
            c += v;
            cont++;
        }
        return retorno;
    }
    gerar(fatores_corretivos_de_terreno) {
        var row = []
        this.chunk(Object.keys(fatores_corretivos_de_terreno), 3).map((arr, keyr) => {
            let cols = []
            arr.map((fator, key) => {
                cols[key] = (<div className="col-sm-4"><h6 className="text-dark mb-0 mt-0">{fator}</h6>
                    <small className="text-dark">{fatores_corretivos_de_terreno[fator]}</small>
                </div>)
            })
            row[keyr] = <div className="container">
                <div className={"row mt-2"}>{cols}</div>
            </div>
        })
        return row
    }
    render() {
        const {
            imovel,
            fatores_corretivos_de_edificacao,
            fatores_corretivos_de_terreno,
            quantidade_de_fatores_terreno,
            quantidade_de_fatores_edificacao,
            soma_fatores_edificacao,
            soma_fatores_terreno,
            fator_corretivo_de_edificacao,
            fator_corretivo_de_terreno,
            valor_venal_de_edificacao,
            valor_venal_de_terreno,
            valor_m2_edificacao,
            valor_venal,
            aliquota,
            valor_m2_terreno
        } = this.data;

        return <div className="book">
            <div className="page">
                <h4 className="text-center text-dark mb-4 mt-4"><strong>Demonstrativo de cálculo de IPTU</strong></h4>
                <div className="container">
                    <div className="row shadow-sm p-3 mb-5 bg-white rounded">
                        <div className="col-sm-12">
                            <small className="text-dark mb-0 mt-4"><strong>Inscrição: {imovel.id}</strong></small>
                        </div>
                        <div className="col-sm-12">
                            <small className="text-dark mb-0 mt-4"><strong>Inscrição Cartográfica: {imovel.inscricao_cartografica}</strong></small>
                        </div>
                        <div className="col-sm-12">
                            <small className="text-dark mb-0 mt-4"><strong>Nome/Razão Social do Contribuinte: {imovel.contribuinte.nome_razao_social}</strong></small>
                        </div>
                        <div className="col-sm-12">
                            <small className="text-dark mb-0 mt-4"><strong>Número: {imovel.numero}</strong></small>
                        </div>
                        <div className="col-sm-12">
                            <small className="text-dark mb-0 mt-4"><strong>Complemento: {imovel.complemento}</strong></small>
                        </div>
                    </div>
                    <div className="row shadow-sm p-3 mb-5 bg-white rounded">
                        <div className="row" style={{width: "100%"}}>
                            <div className={"col-12"}><h5 className=" text-center mb-3">Fatores Corretivos de
                                Edificação</h5></div>
                        </div>
                        {this.gerar(fatores_corretivos_de_edificacao)}
                        <div className="col-sm-4">
                            <h6 className="text-dark mb-0 mt-4">Quantidade de Fatores:</h6>
                            <small className="text-dark">{quantidade_de_fatores_edificacao}</small>
                        </div>
                        <div className="col-sm-4">
                            <h6 className="text-dark mb-0 mt-4">Soma de Fatores:</h6>
                            <small className="text-dark">{soma_fatores_edificacao}</small>
                        </div>
                        <div className="col-sm-4">
                            <h6 className="text-dark mb-0 mt-4">Fator Corretivo (Soma/Quantidade):</h6>
                            <small className="text-dark">{fator_corretivo_de_edificacao}</small>
                        </div>
                    </div>

                    <div className="row shadow-sm p-3 mb-5 bg-white rounded">
                        <div className="row" style={{width: "100%"}}>
                            <div className={"col-12"}><h5 className=" text-center mb-3">Fatores Corretivos de
                                Terreno</h5></div>
                        </div>
                        {this.gerar(fatores_corretivos_de_terreno)}
                        <div className="col-sm-4">
                            <h6 className="text-dark mb-0 mt-4">Quantidade de fatores:</h6>
                            <small className="text-dark">{quantidade_de_fatores_terreno}</small>
                        </div>
                        <div className="col-sm-4">
                            <h6 className="text-dark mb-0 mt-4">Soma dos fatores:</h6>
                            <small className="text-dark">{soma_fatores_terreno}</small>
                        </div>
                        <div className="col-sm-4">
                            <h6 className="text-dark mb-0 mt-4">Fator corretivo (Soma/Quantidade):</h6>
                            <small className="text-dark">{fator_corretivo_de_terreno}</small>
                        </div>
                    </div>

                    <div className="row shadow-sm p-3 mb-3 bg-white rounded">
                        <div className="row" style={{width: "100%"}}>
                            <div className={"col-12"}><h5 className=" text-center mb-3">Valores Venais</h5></div>
                        </div>
                        <div className="row">
                            {/* <div className="col-sm-6">
                                <h6 className="text-dark mb-0">Valor do metro quadrado de terreno (Baseado em seção de
                                    logradouro):</h6>
                                <small className="text-dark mt-0">{valor_m2_terreno}</small>
                            </div>
                            <div className="col-sm-6">
                                <h6 className="text-dark mb-0">Valor do metro quadrado de edificação (Baseado em
                                    classificação arquitetônica):</h6>
                                <small className="text-dark">{valor_m2_edificacao}</small>
                            </div>
                            <div className="col-sm-6">
                                <h6 className="text-dark mb-0">Valor venal do terreno (Área edificacada x Valor do m² x
                                    Fator corretivo de edificação):</h6>
                                <small className="text-dark mb-0">{valor_venal_de_terreno}</small>
                            </div>
                            <div className="col-sm-6">
                                <h6 className="text-dark mb-0">Valor venal da edifificação (Área edificada x Valor do m²
                                    x Fator corretivo de edificação):</h6>
                                <small className="text-dark mb-0">{valor_venal_de_edificacao}</small>
                            </div> */}
                            <Grid container className="mt-4">
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h5 className="text-center">Valor Declarado:</h5>
                                    <h5 className="text-center mb-2">{numeral(parseFloat(valor_venal))
                                        .format("0.0,00")
                                        .slice(0, -2)}</h5>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h5 className="text-center">Alíquota : {numeral(parseFloat(aliquota))
                                        .format("0.0,00")
                                        .slice(0, -2)} %</h5>
                                    <h5 className="text-center">Valor do imposto :
                                        R${numeral(((parseFloat(aliquota) * parseFloat(valor_venal)) / 100))
                                            .format("0.0,00")
                                            .slice(0, -2)}</h5>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}