import React from "react"

import Grid from "@material-ui/core/Grid"
import { withRouter } from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import SearchIcon from "@material-ui/icons/Search"
import DeleteIcon from "@material-ui/icons/Delete"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"
import TablePagination from "@material-ui/core/TablePagination"
import Lens from "@material-ui/icons/Lens"
import Warning from "@material-ui/icons/Warning"
import { showNotification, showWaiting, hideWaiting } from "../../../util/Constants"
import LoadingContent from "../../../components/LoadingContent"
import { simpleSearch as searchContribuinte } from "../../../util/contribuinte"
import AsyncSelect from "../../../components/AsyncSelect"
import SearchFields from "../../../components/SearchFields"
import housecall from "housecall"
import { getDividaComFiltros, getReceitas, demonstrativoDeDivida } from "../../../util/dividas"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import DemonstrativoTab from "../../../components/DemonstrativoTab"
import { contribuinteChanged } from "../../../actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
class Demonstrativo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            contribuintes: [],
            dividas: [],
            dividasFiltered: [],
            value: 0,
            pageCount: 0,
            perPage: 0,
            page: 0,
            search_text: "",
            search: {},
            receitas: [],
            nao_tem_divida: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
        this.getDividas = this.getDividas.bind(this)
        this.handleTab = this.handleTab.bind(this)
        this.getContribuinteById = this.getContribuinteById.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.cleanAdvancedSearch = this.cleanAdvancedSearch.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.getReceitas = this.getReceitas.bind(this)
    }

    componentWillMount() {
        this.getReceitas()
    }

    getReceitas() {
        const response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Erro ao carregar as receitas",
                    "danger"
                )
            } else {
                this.setState({
                    receitas: data
                })
            }
            hideWaiting()
        }
        getReceitas(response)
    }

    handleTab(event, value) {
        this.setState({ value })
    }

    getDividas(page) {
        showWaiting()
        if (!page || typeof page === "object") {
            page = 0
            this.setState({
                page: 0
            })
        }
        let arrayParams = new Array()
        Object.entries({ ...this.state.search, "page": page }).forEach(([key, value]) => {
            arrayParams.push(`${key}=${value}`)
        })
        const params = `?${arrayParams.join("&")}`
        const response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                this.setState({
                    pageCount: data.total,
                    perPage: data.per_page,
                    page: parseInt(data.current_page) - 1,
                    dividas: data.data,
                    nao_tem_divida: data.data.length === 0
                })
            }
            hideWaiting()
        }
        if (!this.state.contribuinte_id) {
            showNotification(
                "top",
                "center",
                "Contribuinte é obrigatório",
                "warning"
            )
            hideWaiting()
        } else {
            getDividaComFiltros(this.state.contribuinte_id.value, params, response)
        }
    }

    handleChangePage(event, page) {
        let pagina = page + 1
        this.setState({ page: page })
        if (page < 0) {
            return
        }
        if (Object.keys(this.state.search).length > 0) {
            this.getDividas(pagina)
            return
        }
        this.getDividas(pagina)
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                                ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })
                    this.setState({
                        contribuintes: data.data
                    })
                    callback(contribuintes)
                }
            }
        )
    }

    async handleChange(event) {
        const name = event.target.name
        const errors = this.state.errors
        await this.setState({
            [name]: event.target.value,
            errors: errors
        })
    }
    async handleSearchChange(event) {
        const name = event.target.name.replace("search_", "")
        if (event.target.value) {
            await this.setState({
                search: { ...this.state.search, [name]: event.target.value }
            })
        } else {
            delete this.state.search[name]
            await this.setState({
                search: { ...this.state.search }
            })
        }
    }
    async cleanAdvancedSearch() {
        await this.setState({
            search: {}
        })
        this.advancedSearch()
    }
    getContribuinteById(contribuinteId) {
        return this.state.contribuintes.filter((item) => {
            return item.id === contribuinteId
        })[0]
    }
    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let arrayParams = new Array()
        Object.entries({ ...this.state.search, "contribuinte_id": this.state.contribuinte_id.value }).forEach(([key, value]) => {
            arrayParams.push(`${key}=${value}`)
        })
        const params = `?${arrayParams.join("&")}`
        const response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
            hideWaiting()
        }
        demonstrativoDeDivida(params, response)
    }
    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    searchFilter(value) {
        let filtered = this.state.dividas
        if (value !== "") {
            this.setState({
                search: true,
                search_text: value
            })
        } else {
            this.setState({
                search: false
            })
        }
        filtered = filtered.filter((filter) => {
            if (filter.codigo_de_origem !== null) {
                return (filter.codigo_de_origem + "").indexOf(value.toUpperCase()) !== -1
            } else {
                return null
            }
        })
        this.setState({ dividasFiltered: filtered })
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ]
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <div>
                        <Card>
                            <form onSubmit={this.handleSave}>
                                <CardHeader color="primary">
                                    <Grid container direction="row">
                                        <Grid item lg={10} xs={12}>
                                            <h2 className={classes.cardTitleWhite}>
                                                Demonstrativo de dívida ativa
                                            </h2>
                                        </Grid>
                                    </Grid>
                                </CardHeader>
                                <CardBody>
                                    <Grid container direction="row" spacing={16}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <SearchFields
                                                name="filterField"
                                                value={this.state.filterField || ""}
                                                onChangeFields={this.handleChange}
                                                fields={fields}
                                            />
                                            <Typography color="textSecondary" variant="caption">
                                                Pesquise o Contribuinte
                                            </Typography>
                                            <AsyncSelect
                                                className={
                                                    `form-control form-control-alternative form-select Select-menu-outer ${this.props.error ? "has-danger" : ""
                                                    }`
                                                }
                                                value={this.state.contribuinte_id}
                                                onChange={this.handleChange}
                                                loadOptions={this.loadOptions}
                                                error={this.state.errors.contribuinte_id}
                                                helperText={this.state.errors.contribuinte_id}
                                                name="contribuinte_id"
                                                placeholder="Contribuinte"
                                                message="Pesquise o contribuinte"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={16}>
                                        <Grid item xs={12} sm={6} md={2} lg={1}>
                                            <TextField
                                                id="outlined-select-currency"
                                                label="Competência"
                                                fullWidth
                                                name="search_competencia"
                                                value={this.state.search.competencia || ""}
                                                onChange={this.handleSearchChange}
                                                margin="normal"
                                                variant="outlined"
                                                defaultValue=""
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextField
                                                id="outlined-select-currency"
                                                label="Origem"
                                                fullWidth
                                                name="search_codigo_de_origem"
                                                value={this.state.search.codigo_de_origem || ""}
                                                onChange={this.handleSearchChange}
                                                margin="normal"
                                                variant="outlined"
                                                defaultValue=""
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={4}>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                name="search_receita_id"
                                                label="Receita"
                                                fullWidth
                                                value={this.state.search.receita_id || ""}
                                                onChange={this.handleSearchChange}
                                                margin="normal"
                                                variant="outlined"
                                            >
                                                {this.state.receitas.map((option, key) => (
                                                    <MenuItem key={key} value={option.id}>
                                                        {`${option.id.toString().padStart(3, "0")} ${option.nome}`}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={16} justify={"flex-end"}>
                                        <Grid item xs={12} sm={4} md={3} lg={2}>
                                            <Button block color="danger" fullWidth onClick={this.cleanAdvancedSearch}>
                                                <DeleteIcon /> Limpar Busca
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3} lg={2}>
                                            <Button block color="primary" fullWidth onClick={this.getDividas}>
                                                <SearchIcon /> Buscar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {this.state.dividas.length > 0 ? (
                                        <div>
                                            <Grid className={classes.legend} container direction="row">
                                                <Lens className={classes.dot + " " + classes.dotSuccess} /> Paga
                                                <Lens className={classes.dot + " " + classes.dotDanger} /> Cancelada
                                                <Lens className={classes.dot + " " + classes.dotPurple} /> Em pagamento
                                                <Lens className={classes.dot + " " + classes.dotInfo} /> Em análise
                                                <Lens className={classes.dot + " " + classes.dotWarning} /> Inativa
                                                <Lens className={classes.dot + " " + classes.dotPrimary} /> Isento
                                                <Lens className={classes.dot + " " + classes.dotGray} /> Quitado
                                                <Lens className={classes.dot + " " + classes.dotRose} /> Prescrito
                                                <Lens className={classes.dot + " " + classes.dotSecondary} /> Em execução
                                                <Lens className={classes.dot + " " + classes.dotBlack} /> Protesto
                                            </Grid>
                                            <div>
                                                {this.state.dividas.map((item) => {
                                                    return (
                                                        <DemonstrativoTab onUpdate={this.getDividas} key={item.id} item={item} />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    ) : (this.state.nao_tem_divida && (
                                        <Grid className={classes.legend} container direction="row">
                                            <Warning className={classes.dot + " " + classes.dotDanger} />
                                        Este contribuinte não possui dívida ativa
                                        </Grid>)
                                        )
                                    }
                                    <TablePagination
                                        component="div"
                                        rowsPerPageOptions={[]}
                                        count={this.state.pageCount}
                                        rowsPerPage={parseInt(this.state.perPage)}
                                        page={this.state.page}
                                        backIconButtonProps={
                                            { "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page",
                                        }}
                                        SelectProps={{
                                            native: true
                                        }}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </CardBody>
                                <CardFooter>
                                    <Grid container justify="center" spacing={8}>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <Button disabled={this.state.contribuinte_id === undefined} block round color="info" onClick={this.handleSave}>
                                                Imprimir
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <Button
                                                disabled={this.state.contribuinte_id === undefined}
                                                block
                                                round
                                                onClick={() => {
                                                    let contribuinte = this.getContribuinteById(this.state.contribuinte_id.value)
                                                    contribuinte.origem = this.state.search.codigo_de_origem ? this.state.search.codigo_de_origem : ""
                                                    contribuinte.receita = this.state.search.receita_id ? this.state.search.receita_id : ""
                                                    contribuinte.competencia = this.state.search.competencia ? this.state.search.competencia : ""
                                                    this.props.contribuinteChanged(contribuinte)
                                                    this.props.history.push("/divida/pagamento/create")
                                                }}
                                                style={{ textDecoration: "none" }} color="success">
                                                Pagar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <Button
                                                disabled={this.state.contribuinte_id === undefined}
                                                block
                                                round
                                                onClick={() => {
                                                    let contribuinte = this.getContribuinteById(this.state.contribuinte_id.value)
                                                    this.props.contribuinteChanged(contribuinte)
                                                    this.props.history.push("/divida/refinanciamento/create")
                                                }}
                                                style={{ textDecoration: "none" }} color="info">
                                                Refinanciamento
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardFooter>
                            </form>
                        </Card>
                    </div>
                </LoadingContent>
            </div>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ contribuinteChanged }, dispatch)
}
Demonstrativo = connect(
    null,
    mapDispatchToProps
)(Demonstrativo)
Demonstrativo = withRouter(Demonstrativo)
export default withStyles(secaoStyle)(Demonstrativo)
