import React from "react";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import Button from "../../../components/CustomButtons/Button";
import secaoStyle from "../../../assets/css/layout/secaoStyle";
import { withStyles, InputAdornment, Switch, FormControlLabel} from "@material-ui/core";
import { post, getField, edit, put } from "../../../util/refis";
import { showNotification, mapErrors } from "../../../util/Constants";
import NumberFormatCustom from "../../../components/NumberFormatCustom";
import LoadingContent from "../../../components/LoadingContent";
import SelectCustom from "../../../components/SelectCustom"

import { put as putDesconto, destroy } from "../../../util/descontoRefis";

class RefisForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      data_inicio: new Date(),
      data_fim: new Date(),
      valor_minimo_fisica: "",
      valor_minimo_juridica: "",
      lei: "",
      modal: false,
      abragencias: [],
      id_abragencias: [],
      //dialog
      parcela: 1,
      percentual_correcao: "",
			percentual_juros: "",
      percentual_multa: "",
      //
      descontos: [
        {
          parcela: 1,
          percentual_correcao: "",
			    percentual_juros: "",
          percentual_multa: "",
        }
      ],
      editingItem: 0,
      itemKeyID: 0,
      currentDescontos: [],
      isLoadingDialog: false,
      id_parcela: 0,
      isPeriodoParcelas: false
    };
    this.init = this.init.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.toggleDesconto = this.toggleDesconto.bind(this);
    
    this.toggleLoadingDialog = this.toggleLoadingDialog.bind(this);
    this.handleSaveDesconto = this.handleSaveDesconto.bind(this);
  }

  componentWillMount() {
    this.init();
  }

  handleSaveDesconto(event) {
    event.preventDefault();
    let paramsF = {
      percentual_correcao: this.state.percentual_correcao 
      ? this.state.percentual_correcao 
      : Number(this.state.currentDescontos[this.state.itemKeyID].percentual_correcao),
      percentual_multa: this.state.percentual_multa 
      ? this.state.percentual_multa 
      : Number(this.state.currentDescontos[this.state.itemKeyID].percentual_multa),
      percentual_juros: this.state.percentual_juros
      ? this.state.percentual_juros
      : Number(this.state.currentDescontos[this.state.itemKeyID].percentual_juros),
      parcela: this.state.editingItem,
      refis_id: this.props.match.params.id
    };
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        showNotification(
          "top",
          "center",
          `Desconto editado com sucesso`,
          "success"
        );
        this.handleClose();
        this.init();
      }
    };
    putDesconto(this.state.id_parcela, paramsF, response);
  }

  addItem() {
    var oldDescontos = this.state.descontos;
    oldDescontos.push({
        parcela: this.state.currentDescontos.length + this.state.descontos.length + 1,
        percentual_correcao: "",
        percentual_juros: "",
        percentual_multa: ""
    });
    this.setState({
      descontos: oldDescontos
    });
  }

  handleOpen = () => {
    this.setState({ modal: true });
  };

  handleClose = () => {
    this.setState({ modal: false });
  };

  toggleDesconto(event, index, itemID) {
    this.setState({
        editingItem: index+1,
        itemKeyID: index,
        modal: !this.state.modal,
        id_parcela: itemID
      }, () => {
        console.log(this.state.editingItem)
        console.log(this.state.id_parcela)
      });
  }

  removeItem() {
    this.setState(state => ({
      descontos: state.descontos.filter((_, key) => {
        return key !== this.state.descontos.length-1;
      })
    }));
  }

  removeCurrentDesconto(event, index, item) {
    event.preventDefault();
    var items = this.state.currentDescontos.slice()

    items.splice(index, 1)

    var sort = items.sort((a, b) => {
        return a.parcela < b.parcela ? -1 : 1;
    }).map((item, key) => {
       let ret = Object.assign({}, item, {
        parcela: key+1
      })
      return ret
    }
    );
    
    this.setState({
      currentDescontos: sort
    });

    //request
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        showNotification(
          "top",
          "center",
          `Desconto deletado com sucesso`,
          "success"
        );
        this.handleClose();
      }
    };
    destroy(item, response)
  }

  toggleLoadingDialog() {
    this.setState(state => ({
      isLoadingDialog: !state.isLoadingDialog
    }));
  }

  reloadPreposto(newDesconto) {
    var oldCurrentDescontos = this.state.currentDescontos;
    oldCurrentDescontos.forEach((desconto, key) => {
      if (desconto.id === newDesconto.id) {
        oldCurrentDescontos[key] = newDesconto;
      }
    });
    //this.toggleDesconto()
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    if (name.split(".").length > 1) {
      let olddescontos = [...this.state.descontos];
      let components = name.split(".");
      name = components[0];
      olddescontos[components[1]][components[2]] = value;
      value = olddescontos;
    }
    this.setState(state => ({
      [name]: value,
      errors: { ...state.errors, [name]: undefined }
    }));
    if(name === 'isPeriodoParcelas'){
      this.setState({
        isPeriodoParcelas: !this.state.isPeriodoParcelas
      })
    }
  }

  handleSave(event) {
    event.preventDefault();
    let params = {
      lei: this.state.lei,
      inicio_validade: moment(this.state.data_inicio).format("YYYY-MM-DD"),
      fim_validade: moment(this.state.data_fim).format("YYYY-MM-DD"),
      valor_minimo_de_parcela_pf: this.state.valor_minimo_fisica,
      valor_minimo_de_parcela_pj: this.state.valor_minimo_juridica,
      descontos: this.state.descontos,
      parcela_periodo: this.state.isPeriodoParcelas
    };
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        showNotification(
          "top",
          "center",
          `REFIS ${
            this.props.match.params.id ? "editado" : "incluído"
          } com sucesso`,
          "success"
        );
        this.props.history.push("/divida/cadastro")
      }
    };
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }));
  }
  init() {
    this.toggleLoading()
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        );
      } else {
        let abrangencias = data.receitas.map(receita => {
          return { value: receita.id, label: receita.nome }
        })
        this.setState({
          abragencias: abrangencias
        });
        if (this.props.match.params.id) {
          data.data_inicio = data.refis.inicio_validade
          data.data_fim = data.refis.fim_validade
          data.valor_minimo_fisica = data.refis.valor_minimo_de_parcela_pf
          data.valor_minimo_juridica = data.refis.valor_minimo_de_parcela_pj

          data.id_abragencias = data.refis.abrangencia.map(item => {
            return { value: item.id, label: item.nome }
          })

          this.setState({
            isPeriodoParcelas: !!data.refis.parcela_periodo, 
            currentDescontos: data.refis.descontos,
            descontos: [],
            data_inicio: data.data_inicio,
            data_fim: data.data_fim,
            valor_minimo_fisica: data.valor_minimo_fisica,
            valor_minimo_juridica: data.valor_minimo_juridica,
            lei: data.refis.lei,
            id_abragencias: data.id_abragencias
          }, () => {
            this.state.currentDescontos.sort((a, b) => {
              return a.parcela < b.parcela ? -1 : 1;
          })
          })
        }
        this.toggleLoading()
      }
    };
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      getField(response)
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
        <div>
          <Card>
            <form onSubmit={this.handleSave}>
              <CardHeader color="primary">
                <Grid container direction="row">
                  <Grid item lg={10} xs={12}>
                    <h2 className={classes.cardTitleWhite}>
                      Cadastro de Refis
                    </h2>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody>
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  variant="caption"
                >
                  Validade REFIS
                </Typography>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="date"
                      type="date"
                      variant="outlined"
                      margin="normal"
                      label="Data Início"
                      className={classes.datePicker}
                      error={this.state.errors.inicio_validade}
                      helperText={this.state.errors.inicio_validade}
                      defaultValue={moment(this.state.data_inicio).format(
                        "YYYY-MM-DD"
                      )}
                      value={this.state.data_inicio}
                      name="data_inicio"
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="date"
                      type="date"
                      variant="outlined"
                      margin="normal"
                      name="data_fim"
                      label="Data Fim"
                      className={classes.datePicker}
                      helperText={this.state.errors.fim_validade}
                      value={this.state.data_fim}
                      error={this.state.errors.fim_validade}
                      defaultValue={moment(this.state.data_fim).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={this.handleChange}
                    />
                  </Grid>
                </Grid>
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  variant="caption"
                >
                  Valor Mínimo
                </Typography>
                <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="outlined-select-currency"
                      label="Pessoa Física"
                      fullWidth
                      required
                      defaultValue={this.state.valor_minimo_fisica}
                      value={this.state.valor_minimo_fisica}
                      onChange={this.handleChange}
                      name="valor_minimo_fisica"
                      error={this.state.errors.valor_minimo_de_parcela_pf}
                      helperText={this.state.errors.valor_minimo_de_parcela_pf}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="outlined-select-currency"
                      label="Pessoa Jurídica"
                      fullWidth
                      required
                      defaultValue={this.state.valor_minimo_juridica}
                      value={this.state.valor_minimo_juridica}
                      onChange={this.handleChange}
                      name="valor_minimo_juridica"
                      error={this.state.errors.valor_minimo_de_parcela_pj}
                      helperText={this.state.errors.valor_minimo_de_parcela_pj}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="outlined-select-currency"
                      label="Lei"
                      fullWidth
                      required
                      value={this.state.lei}
                      onChange={this.handleChange}
                      name="lei"
                      multiline
                      rowsMax="5"
                      error={this.state.errors.lei}
                      helperText={this.state.errors.lei}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                
              </CardBody>
              
            </form>
          </Card>
          <Card>
          <CardHeader>
              <h4 className={classes.cardTitle}>Descontos</h4>
            </CardHeader>
            <CardBody>
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          name={'isPeriodoParcelas'}
                          defaultChecked={this.state.isPeriodoParcelas}
                          checked={
                            this.state.isPeriodoParcelas
                          }
                          onChange={this.handleChange}
                          value={this.state.isPeriodoParcelas}
                        />
                      }
                      label="Adicionar desconto por periodo?"
                    />
                  </Grid>
                </Grid>
                {this.state.currentDescontos.map((desconto, key) => {
                  return (
                    <div>
                      <Grid container spacing={16} key={desconto.id}>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <TextField
                          fullWidth
                          required
                          margin="normal"
                          variant="outlined"
                          disabled
                          label="Quantidade de Parcela"
                          type="text"
                          defaultValue={desconto.parcela}
                          value={desconto.parcela}
                        />
                      </Grid>
                      {this.state.isPeriodoParcelas && (
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField
                          fullWidth
                          required
                          margin="normal"
                          variant="outlined"
                          label="Quantidade de Parcela"
                          type="text"
                          disabled
                          name={desconto.parcelaFim}
                          value={desconto.parcelaFim}
                          onChange={this.handleChange}
                          error={
                            this.state.errors[desconto.parcelaFim]
                          }
                          helperText={this.state.errors[desconto.parcelaFim]}
                        />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                      <TextField
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Correção"
                          disabled
                          defaultValue={desconto.percentual_correcao}
                          value={desconto.percentual_correcao}
                          type="text"
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                      <TextField
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Juros"
                          type="text"
                          disabled
                          InputLabelProps={{
                            shrink: true
                          }}
                          defaultValue={desconto.percentual_juros}
                          value={desconto.percentual_juros}
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                      <TextField
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Multa"
                          type="text"
                          disabled
                          InputLabelProps={{
                            shrink: true
                          }}
                          defaultValue={desconto.percentual_multa}
                          value={desconto.percentual_multa}
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                        />
                      </Grid>
                    </Grid>
                      <Grid container spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <Button
                            type="button"
                            fullWidth
                            onClick={event =>
                              this.removeCurrentDesconto(
                                event,
                                key,
                                desconto.id
                              )
                            }
                            color="danger"
                          >
                            <Delete /> Remover desconto
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <Button
                            fullWidth
                            type="button"
                            onClick={event =>
                              this.toggleDesconto(event, key, desconto.id)
                            }
                            color="primary"
                          >
                            <Edit /> Editar desconto
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}

                {this.state.descontos.map((desconto, key) => {
                  return (
                    <Grid container spacing={16} key={key}>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <TextField
                          fullWidth
                          required
                          margin="normal"
                          variant="outlined"
                          disabled={key >= 0 ? false : true}
                          label="Quantidade de Parcela"
                          type="number"
                          name={`desconto.${key}.parcela`}
                          value={desconto.parcela}
                          onChange={this.handleChange}
                          error={
                            this.state.errors[`descontos.${key}.parcela`]
                          }
                          helperText={this.state.errors[`descontos.${key}.parcela`]}
                        />
                      </Grid>
                      {this.state.isPeriodoParcelas && (
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField
                          fullWidth
                          required
                          margin="normal"
                          variant="outlined"
                          label="Quantidade de Parcela"
                          type="number"
                          name={`desconto.${key}.parcelaFim`}
                          value={desconto.parcelaFim}
                          onChange={this.handleChange}
                          error={
                            this.state.errors[`descontos.${key}.parcelaFim`]
                          }
                          helperText={this.state.errors[`descontos.${key}.parcelaFim`]}
                        />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                      <TextField
                          error={
                            this.state.errors[`descontos.${key}.percentual_correcao`]
                          }
                          helperText={this.state.errors[`descontos.${key}.percentual_correcao`]}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Correção"
                          name={`desconto.${key}.percentual_correcao`}
                          type="number"
                          onChange={event =>
                            this.handleChange({
                              target: {
                                name: `desconto.${key}.percentual_correcao`,
                                value: event.target.value
                              }
                            })
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={desconto.percentual_correcao}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                      <TextField
                          error={
                            this.state.errors[`descontos.${key}.percentual_juros`]
                          }
                          helperText={this.state.errors[`descontos.${key}.percentual_juros`]}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Juros"
                          name={`desconto.${key}.percentual_juros`}
                          type="number"
                          onChange={event =>
                            this.handleChange({
                              target: {
                                name: `desconto.${key}.percentual_juros`,
                                value: event.target.value
                              }
                            })
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={desconto.juros}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                      <TextField
                          error={
                            this.state.errors[`descontos.${key}.percentual_multa`]
                          }
                          helperText={this.state.errors[`descontos.${key}.percentual_multa`]}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Multa"
                          name={`desconto.${key}.percentual_multa`}
                          type="number"
                          onChange={event =>
                            this.handleChange({
                              target: {
                                name: `desconto.${key}.percentual_multa`,
                                value: event.target.value
                              }
                            })
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={desconto.multa}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid
                  container
                  direction="row"
                  style={{ marginTop: "2%" }}
                  spacing={16}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Button
                      type="button"
                      fullWidth
                      onClick={() => this.removeItem()}
                      color="danger"
                    >
                      <Delete /> Remover desconto
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Button
                      fullWidth
                      type="button"
                      onClick={this.addItem}
                      color="success"
                    >
                      <AddIcon /> Adicionar desconto
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
        </Card>
        
        <Card>
          <CardHeader>
              <h4 className={classes.cardTitle}>Abrangência</h4>
          </CardHeader>
          <CardBody>
          <SelectCustom
                  value={this.state.id_abragencias}
                  errors={this.state.errors.id_abragencias}
                  onChange={this.handleChange}
                  options={this.state.abragencias}
                  name="id_abragencias"
                  placeholder="Abrangências"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
          </CardBody>
          <CardFooter>
                <Grid container justify="center">
                  <Grid item lg={2}>
                    <Button block color="info" round onClick={this.handleSave}>
                      <AddIcon /> Adicionar
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
        </Card>
        
        </div>
        </LoadingContent>
        {/* MODAL */}
        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={this.handleSaveDesconto}>
            <DialogTitle id="form-dialog-title">Atualiza desconto</DialogTitle>
            <LoadingContent isLoading={this.state.isLoadingDialog}>
              <DialogContent>
              <Grid container spacing={16}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                          error={this.state.errors.percentual_correcao}
                          helperText={this.state.errors.percentual_correcao}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Correção"
                          name="percentual_correcao"
                          defaultValue={this.state.modal ? this.state.currentDescontos[this.state.itemKeyID].percentual_correcao : 0}
                          type="text"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                          error={this.state.errors.percentual_juros}
                          helperText={this.state.errors.percentual_juros}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Juros"
                          name="percentual_juros"
                          defaultValue={this.state.modal ? this.state.currentDescontos[this.state.itemKeyID].percentual_juros : 0}
                          type="text"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                          error={this.state.errors.percentual_multa}
                          helperText={this.state.errors.percentual_multa}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Multa"
                          name="percentual_multa"
                          defaultValue={this.state.modal ? this.state.currentDescontos[this.state.itemKeyID].percentual_multa : 0}
                          type="text"
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                        />
                      </Grid>
                    </Grid>
              </DialogContent>
            </LoadingContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Cancelar
              </Button>
              <Button onClick={this.handleSaveDesconto} color="success">
              Atualizar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(secaoStyle)(RefisForm);
