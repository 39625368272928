import React from "react"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import { dam } from "../../util/dam"
import { post as postDam } from "../../util/geracaoDam"
import { withStyles, MenuItem, InputAdornment } from "@material-ui/core"
import logradouroStyle from "../../assets/css/layout/logradouroStyle"
import Button from "../../components/CustomButtons/Button"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import Grid from "@material-ui/core/Grid"
import { showNotification, mapErrors, showWaiting, hideWaiting, removeMask } from "../../util/Constants"
import { search as searchContribuinte } from "../../util/contribuinte"
import { getField, post, edit, put, getCnae } from "../../util/alvara"
import LoadingContent from "../../components/LoadingContent"
import AsyncSelect from "../../components/AsyncSelect"
import SearchFields from "../../components/SearchFields"
import CardFooter from "../../components/Card/CardFooter"
import moment from "moment"
import FastSelect from "../../components/FastSelect"
import housecall from "housecall"
import InputMask from "react-input-mask"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import Delete from "@material-ui/icons/Delete"
import Edit from "@material-ui/icons/Edit"
import { getSocio, putSocio, destroySocio } from "../../util/socio"


const CNAES = ({ cnaes, errors, handleChange, removeItemCnae }) => cnaes.map((cna, key) =>
  <Grid container spacing={16} key={key} alignItems="center">
    <Grid item xs={12} sm={12} md={2} lg={2}>
      <TextField
        fullWidth
        required
        margin="normal"
        variant="outlined"
        label="Código"
        type="number"
        name={`cnaes.${key}.id`}
        value={cna.id}
        onChange={handleChange}
        error={errors[`cnaes.${key}.id`]}
        helperText={errors[`cnaes.${key}.id`]}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={9} lg={9}>
      <TextField
        fullWidth
        required
        margin="normal"
        variant="outlined"
        label="Descrição"
        disabled
        type="text"
        name={`cnaes.${key}.descricao`}
        value={cna.descricao}
        onChange={handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={1} lg={1}>
      <Button
        type="button"
        fullWidth
        onClick={() => removeItemCnae(key)}
        color="danger"
      >
        <Delete />
      </Button>
    </Grid>
  </Grid>
)

const Socios = ({ socios, handleChange, errors }) => socios.map((socio, key) => {
  return (
    <Grid container spacing={16} key={key} alignItems="center">
      <Grid item xs={12} sm={12} md={5} lg={5}>
        <TextField
          fullWidth
          required
          margin="normal"
          variant="outlined"
          label="Nome"
          type="text"
          name={`socios.${key}.nome`}
          onChange={handleChange}
          error={errors[`socios.${key}.nome`]}
          helperText={errors[`socios.${key}.nome`]}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5}>
        <InputMask
          mask={"999.999.999-99"}
          onChange={handleChange}
        >{() => (
          <TextField
            fullWidth
            required
            margin="normal"
            variant="outlined"
            label="CPF"
            type="text"
            name={`socios.${key}.cpf`}
            onChange={handleChange}
            error={errors[`socios.${key}.cpf`]}
            helperText={errors[`socios.${key}.cpf`]}
          />)}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                name={`socios.${key}.principal`}
                defaultChecked={socio.principal}
                checked={
                  socio.principal
                }
                onChange={handleChange}
                value={`socios.${key}.principal`}
              />
            }
            label="Principal"
          />
        </FormGroup>
      </Grid>
    </Grid>
  )
})
class AlvaraForm extends React.Component {
  constructor() {
    super()
    this.state = {
      contribuintes: [],
      id_contribuintes: "",
      errors: {},
      //
      cpf_cnpj: "",
      inscricao_municipal: "",
      tipos_alvara: [],
      taxas: [],
      id_taxas: 1,
      tipos_declaracao: [],
      tipo_declaracao_id: '',
      tipos_recolhimento: [],
      tipo_recolhimento_id: '',
      atividade: [],
      id_atividade: 1,
      desc_atividade: "",
      horario_inicio: "",
      horario_fim: "",
      isento: [{ value: true, label: "Sim" }, { value: false, label: "Não" }],
      id_isento: false,
      radioEndereco: "",

      //
      nome_fantasia: "",
      logradouro: "",
      cidade: "",
      uf: "",
      bairro: "",
      complemento: "",
      cep: "",
      inscricao_iptu: "",
      // veiculo
      data_inscricao: "",
      utilizacao: [],
      id_utilizacao: 0,
      chassi: "",
      placa: "",
      ano: "",
      marca: "",
      modelo: "",
      cor: "",

      id_imovel: "",
      imovel: [],
      modal: false,
      modalDam: false,

      //campos emitir dam
      mes_dialog: "",
      ano_dialog: "",
      data_vencimento_dialog: "",
      historico_dialog: "",
      data_validade_dialog: "",
      id_alvara: "",
      data_de_abertura: new Date(),
      contador_responsavel: "",
      socios: [
      ],
      currentSocios: [],
      cnaes: [

      ],
      //dialog
      nome: "",
      cpf: "",
      principal: false,
      editingItem: 0,
      //dialog cnaes
      modalCustom: false,
      isLoadingDialog: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.getContribuinteById = this.getContribuinteById.bind(this)
    this.getData = this.getData.bind(this)
    this.getTaxaById = this.getTaxaById.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 });
    this.handleClose = this.handleClose.bind(this)
    this.openModalDam = this.openModalDam.bind(this)
    this.postEmitirDam = this.postEmitirDam.bind(this)
    this.imprimirDam = this.imprimirDam.bind(this)
    this.addItem = this.addItem.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.handleSaveSocio = this.handleSaveSocio.bind(this)
    this.toggleSocio = this.toggleSocio.bind(this)
    this.getSocio = this.getSocio.bind(this)
    this.toggleLoadingDialog = this.toggleLoadingDialog.bind(this)
    this.handleCloseSocio = this.handleCloseSocio.bind(this)
    this.addItemCnae = this.addItemCnae.bind(this)
    this.removeItemCnae = this.removeItemCnae.bind(this)
    this.cpfMask = this.cpfMask.bind(this)

  }

  cpfMask(value)  {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
  }

  addItemCnae() {
    var oldCnae = this.state.cnaes

    oldCnae.push({
      descricao: "",
      id: ""
    })
    this.setState({
      cnaes: oldCnae
    })
  }

  removeItemCnae(key) {
    console.log(key);
    var oldCnae = [...this.state.cnaes]
    oldCnae.splice(key, 1)
    this.setState({
      cnaes: oldCnae
    });
  }

  toggleLoadingDialog() {
    this.setState(state => ({
      isLoadingDialog: !state.isLoadingDialog
    }))
  }

  getSocio(id) {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          nome: data.nome,
          cpf: data.cpf,
          principal: data.principal,
          editingItem: id
          },
          () => {
            this.toggleLoadingDialog()
          }
        )
      }
    }
    this.toggleLoadingDialog()
    getSocio(id, response)
  }

  handleSaveSocio(event) {
    event.preventDefault()
    let paramsF = {
      id: this.state.editingItem,
      nome: this.state.nome,
      cpf: removeMask(this.state.cpf),
      principal: this.state.principal,
    }
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Sócio editado com sucesso`,
          "success"
        )
        this.handleCloseSocio()
        this.getData()
      }
    }
    putSocio(this.state.editingItem, paramsF, response)
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {}
    if (this.state.id_tipos_alvara === 2) {
      params = {
        contribuinte_id: this.state.id_contribuintes.value,
        tipo_id: this.state.id_tipos_alvara,
        taxa_id: this.state.id_taxas,
        tipo_declaracao_id : this.state.tipo_declaracao_id,
        tipo_recolhimento_id : this.state.tipo_recolhimento_id,
        data_de_abertura: ((this.state.data_de_abertura || "") !== "") ? moment(this.state.data_de_abertura).format("YYYY-MM-DD") : undefined,
        contador_responsavel: this.state.contador_responsavel,
        placa: this.state.placa,
        chassi: this.state.chassi,
        modelo: this.state.modelo,
        cor: this.state.cor,
        numero_da_vaga: this.state.numero_da_vaga,
        marca: this.state.marca,
        utilizacao_id: this.state.id_utilizacao,
        ano: this.state.ano
      }
    } else {
      params = {
        contribuinte_id: this.state.id_contribuintes.value,
        tipo_id: this.state.id_tipos_alvara,
        taxa_id: this.state.id_taxas,
        tipo_declaracao_id : this.state.tipo_declaracao_id,
        tipo_recolhimento_id : this.state.tipo_recolhimento_id,
        atividade_id: this.state.id_atividade,
        descricao_atividade: this.state.desc_atividade || "",
        inicio_funcionamento: moment(this.state.horario_inicio, "HH:mm").format(
          "HH:mm"
        ),
        fim_funcionamento: moment(this.state.horario_fim, "HH:mm").format(
          "HH:mm"
        ),
        area_m2: this.state.area,
        isento: this.state.id_isento,
        nome_fantasia: this.state.nome_fantasia,
        endereco: this.state.logradouro,
        bairro: this.state.bairro,
        cep: this.state.cep,
        cidade: this.state.cidade,
        uf: this.state.uf,
        imovel_id: this.state.id_imovel,
        data_de_abertura: ((this.state.data_de_abertura || "") !== "") ? moment(this.state.data_de_abertura).format("YYYY-MM-DD") : undefined,
        contador_responsavel: this.state.contador_responsavel,
        inscricao_municipal: this.state.inscricao_municipal,
        socios: this.state.socios.map(item => {
          return { ...item, cpf: removeMask(item.cpf) }
        }),
        cnaes: this.state.cnaes
      }
    }
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          `Alvará ${
          this.props.match.params.id ? "editado" : "incluído"
          } com sucesso`,
          "success"
        )
        if (this.props.match.params.id) {
          this.props.history.push("/alvara/cadastro")
        } else {
          this.setState({ modal: true, id_alvara: data.id })
        }

      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }

  removeCurrentSocio(event, key, id) {
    event.preventDefault();

    var delItem = this.state.currentSocios.splice(key, 1)

    this.setState({
      currentSocios: delItem
    });

    //request
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        showNotification(
          "top",
          "center",
          `Sócio deletado com sucesso`,
          "success"
        );
        // this.handleClose();
        this.getData()
      }
    };
    destroySocio(id, response)
  }

  addItem() {
    var oldSocios = this.state.socios

    oldSocios.push({
      nome: "",
      cpf: "",
      principal: false
    })
    this.setState({
      socios: oldSocios
    })
  }

  removeItem() {
    this.setState(state => ({
      socios: state.socios.filter((_, key) => {
        return key !== state.socios.length - 1
      })
    }))
  }

  componentWillMount() {
    this.getData()
  }

  toggleSocio(event, index, itemID) {
    this.setState(
      {
        editingItem: itemID,
        modalCustom: !this.state.modalCustom
      },
      () => {
        this.getSocio(itemID)
      }
    )
  }

  handleCloseSocio() {
    this.setState({ modalCustom: false })
  }

  loadOptions(inputValue, callback) {
    // this.queue.push(() =>
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })

          this.setState({
            contribuintes: data.data
          }, () => {

          })
          callback(contribuintes)
        }
      }
    )
  }

  getContribuinteById(id) {
    return this.state.contribuintes.filter(item => {
      return id === item.id
    })[0]
  }

  getData() {
    this.toggleLoading()
    let response = (error, data) => {
      this.toggleLoading()
      if (error) {
        console.log(this.props.match.params.id,"error",error)
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        // console.log(data.alvara)
        this.setState({
          tipos_alvara: data.tipos_de_alvara,
          utilizacao: data.utilizacoes,
          atividade: data.atividades,
          tipos_declaracao : data.tipos_de_declaracao,
          tipos_recolhimento : data.tipos_de_recolhimento,
          numero_da_vaga:data ? (data.alvara?(data.alvara.dados_veiculo?data.alvara.dados_veiculo.numero_da_vaga:''):''):''
        })
        if (this.props.match.params.id) {
          //contribuintes
          data.alvara.id_contribuintes = {
            value: data.alvara.contribuinte.id,
            label: data.alvara.contribuinte.nome_razao_social
          }
          if (data.alvara.dados_estabelecimento) {
            data.alvara.data_de_abertura = data.alvara.dados_estabelecimento.data_de_abertura
            data.alvara.contador_responsavel = data.alvara.dados_estabelecimento.contador_responsavel
            data.alvara.inscricao_municipal = data.alvara.dados_estabelecimento.inscricao_municipal
          }
          data.alvara.cpf_cnpj = data.alvara.contribuinte.cpf_cnpj
          data.alvara.imovel = data.alvara.contribuinte.imoveis
          data.alvara.id_tipos_alvara = data.alvara.tipo_id
          data.alvara.taxas = this.getTaxaById(data.alvara.tipo_id).taxas
          data.alvara.id_taxas = data.alvara.taxa_id
          data.tipo_declaracao_id = data.alvara.tipo_declaracao_id
          data.tipo_recolhimento_id = data.alvara.tipo_recolhimento_id
          if (data.alvara.tipo_id === 2) {

            data.alvara.cnaes = data.alvara.cnaes.map(item => {
              return { descricao: item.descricao, id: item.id }
            })

            data.alvara.data_de_abertura = !data.alvara.dados_veiculo ? "" : data.alvara.dados_veiculo.data_de_abertura
            data.alvara.chassi        = !data.alvara.dados_veiculo ? "" : data.alvara.dados_veiculo.chassi
            data.alvara.placa         = !data.alvara.dados_veiculo ? "" : data.alvara.dados_veiculo.placa
            data.alvara.modelo        = !data.alvara.dados_veiculo ? "" : data.alvara.dados_veiculo.modelo
            data.alvara.cor           = !data.alvara.dados_veiculo ? "" : data.alvara.dados_veiculo.cor
            data.alvara.marca         = !data.alvara.dados_veiculo ? "" : data.alvara.dados_veiculo.marca
            data.alvara.ano           = !data.alvara.dados_veiculo ? "" : data.alvara.dados_veiculo.ano
            data.alvara.id_utilizacao = !data.alvara.dados_veiculo ? "" : data.alvara.dados_veiculo.utilizacao_id
          } else {
            data.alvara.imovel = data.alvara.contribuinte.imoveis
            data.alvara.id_imovel = !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.imovel_id 
            data.alvara.currentSocios = !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.socios
            data.alvara.id_atividade =
              !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.atividade_id
            data.alvara.desc_atividade =
              !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.descricao_atividade
            data.alvara.horario_inicio =
              !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.inicio_funcionamento
            data.alvara.horario_fim =
              !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.fim_funcionamento
            data.alvara.area = parseFloat(
              !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.area_m2
            ).toFixed(2)
            data.alvara.nome_fantasia =
              !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.nome_fantasia
            data.alvara.logradouro = !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.endereco
            data.alvara.bairro = !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.bairro
            data.alvara.cep = !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.cep
            data.alvara.cidade = !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.cidade
            data.alvara.uf = !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.uf
            data.alvara.inscricao_iptu =
              !data.alvara.dados_estabelecimento ? "" : data.alvara.dados_estabelecimento.inscricao_iptu

              !data.alvara.dados_estabelecimento ? (data.alvara.id_isento = false) : data.alvara.dados_estabelecimento.isento === 0
                ? (data.alvara.id_isento = false)
                : (data.alvara.id_isento = true)
              delete data.alvara.isento
            }
        }

        this.setState({ ...data.alvara })
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      getField(response)
    }
  }

  getTaxaById(id) {
    return this.state.tipos_alvara.filter(item => {
      return id === item.id
    })[0]
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    let value = event.target.value
    console.log(name)
    console.log(value)
    errors[name] = undefined

    if (event.target.type === "checkbox") {
      value = event.target.checked
    }

    if (name.split(".").length > 1 && name.includes("socios")) {
      let oldsocios = [...this.state.socios]
      let components = name.split(".")
      name = components[0]
      oldsocios[components[1]][components[2]] = value
      value = oldsocios
    } else if (name.split(".").length > 1 && name.includes("cnaes")) {
      let oldcnaes = [...this.state.cnaes]
      let components = name.split(".")
      name = components[0]

      oldcnaes[components[1]][components[2]] = value
      if (components[2] === "id") {
        this.foundCnae(value, components[1])
      }
      value = oldcnaes
    }

    this.setState(
      {
        [name]: value,
        errors: errors
      },
      () => {
        if (this.state.id_contribuintes && name === "id_contribuintes") {
          let contr = this.getContribuinteById(
            this.state.id_contribuintes.value
          )
          this.setState({
            cpf_cnpj: contr.cpf_cnpj || "",
            logradouro: contr.endereco,
            nome_fantasia: contr.nome_fantasia,
            cidade: contr.cidade,
            cep: contr.cep,
            uf: contr.uf,
            bairro: contr.bairro,
            imovel: contr.imoveis,
            inscricao_municipal: contr.inscricao_municipal || (contr.id + '').padStart(10, '0'),
            id_imovel: contr.imoveis.length > 0 ? contr.imoveis[0].id : undefined
          })
        }
        if (this.state.id_tipos_alvara && name === "id_tipos_alvara") {
          let tipoAlvara = this.getTaxaById(this.state.id_tipos_alvara)
          this.setState({
            taxas: tipoAlvara.taxas,
            id_taxas: tipoAlvara.taxas[0].id
          })
        }
        if(this.state.tipo_declaracao_id == 4 && name == 'tipo_declaracao_id'){
          this.setState({
            id_isento: true
          })
        }
      }
    )
  }

  handleClose = () => {
    this.props.history.push("/alvara/cadastro")
  }
  openModalDam() {
    this.setState({ modalDam: true, modal: false })
  }
  postEmitirDam() {
    showWaiting()
    let params = {
      mes: this.state.mes_dialog,
      ano: this.state.ano_dialog,
      vencimento: this.state.data_vencimento_dialog,
      historico: this.state.historico_dialog,
      validade: this.state.data_validade_dialog
    }
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        if (error.response.data.errors) {
          this.setState({
            errors: mapErrors(error)
          })
        } else {
          showNotification(
            "top",
            "center",
            error.response.data.message,
            "danger"
          )
        }
      } else {
        hideWaiting()
        showNotification("top", "center", `DAM emitido com sucesso`, "success")
        this.handleClose()
        this.imprimirDam(data.id)
      }
    }
    postDam(params, response, this.state.id_alvara)
  }

  imprimirDam(id) {
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)

        this.props.history.push("/alvara/cadastro")
      }
    }
    dam(id, response)
  }

  foundCnae(codigo, key) {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão",
          "danger"
        )
      } else {
        if (data.length > 0) {
          let newState = Object.assign({}, this.state)
          newState.cnaes[key].descricao = data[0].descricao
          this.setState(newState)
        }
      }
    }
    getCnae(codigo, response)
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <CardHeader color="primary">
              <Grid container direction="row">
                <Grid item lg={10} xs={12}>
                  <h2 className={classes.cardTitleWhite}>Cadastro de Alvará</h2>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid container direction="row" style={{ marginTop: "2%" }}>
                <Divider />
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  variant="caption"
                >
                  Informações do contribuinte
                </Typography>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SearchFields
                    name="filterField"
                    value={this.state.filterField || ""}
                    onChangeFields={this.handleChange}
                    fields={fields}
                  />
                  <AsyncSelect
                    className={
                      `form-control form-control-alternative form-select Select-menu-outer ${
                      this.props.error ? "has-danger" : ""
                      }`
                    }
                    value={this.state.id_contribuintes}
                    onChange={this.handleChange}
                    loadOptions={this.loadOptions}
                    error={this.state.errors.contribuinte_id}
                    helperText={this.state.errors.contribuinte_id}
                    name="id_contribuintes"
                    placeholder="Contribuinte"
                    message="Pesquise o contribuinte"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-name"
                    label="Inscrição Municipal"
                    fullWidth
                    name="inscricao_municipal"
                    type="text"
                    onChange={this.handleChange}
                    error={this.state.errors.inscricao_municipal}
                    helperText={this.state.errors.inscricao_municipal}
                    value={this.state.inscricao_municipal}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-name"
                    label="CPF/CNPJ/DOC"
                    disabled
                    fullWidth
                    value={this.state.cpf_cnpj}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    label="Data de Abertura"
                    name="data_de_abertura"
                    fullWidth
                    type="date"
                    defaultValue={moment(this.state.data_de_abertura).format("YYYY-MM-DD")}
                    value={moment(this.state.data_de_abertura).format("YYYY-MM-DD")}
                    error={this.state.errors.data_de_abertura}
                    helperText={this.state.errors.data_de_abertura}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    label="Contador Responsável"
                    name="contador_responsavel"
                    fullWidth
                    value={this.state.contador_responsavel}
                    error={this.state.errors.contador_responsavel}
                    helperText={this.state.errors.contador_responsavel}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FastSelect
                    required
                    name="tipo_declaracao_id"
                    label="Tipos de Declaração"
                    error={this.state.errors.tipo_declaracao_id}
                    value={this.state.tipo_declaracao_id}
                    onChange={this.handleChange}
                    options={
                      this.state.tipos_declaracao.map((option) => (
                        { value: option.id, label: option.name }
                      ))
                    }
                  >
                  </FastSelect>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FastSelect
                      required
                      name="tipo_recolhimento_id"
                      label="Tipos de Recolhimento"
                      error={this.state.errors.tipo_recolhimento_id}
                      value={this.state.tipo_recolhimento_id}
                      onChange={this.handleChange}
                      options={
                        this.state.tipos_recolhimento.map((option) => (
                          { value: option.id, label: option.name }
                        ))
                      }
                    >
                    </FastSelect>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FastSelect
                    required
                    name="id_tipos_alvara"
                    label="Tipos de Alvará"
                    error={this.state.errors.tipo_id}
                    value={this.state.id_tipos_alvara}
                    onChange={this.handleChange}
                    options={
                      this.state.tipos_alvara.map((option) => (
                        { value: option.id, label: option.descricao }
                      ))
                    }
                  >
                  </FastSelect>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    required
                    name="id_taxas"
                    label="Taxas"
                    fullWidth
                    value={this.state.id_taxas}
                    onChange={this.handleChange}
                    error={this.state.errors.id_taxas}
                    helperText={this.state.errors.taxa_id}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.taxas.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              {this.state.id_tipos_alvara && this.state.id_tipos_alvara !== 2 && (
                <div>
                  {(this.state.currentSocios || []).map((socio, key) => {
                    return (
                      <div key={key}>
                        <Grid container direction="row" style={{ marginTop: "2%" }}>
                          <Divider />
                          <Typography
                            className={classes.dividerFullWidth}
                            color="textSecondary"
                            variant="caption"
                          >
                            Sócios
                        </Typography>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                          <Grid item xs={12} sm={12} md={4} lg={4}>
                            <TextField
                              fullWidth
                              disabled
                              margin="normal"
                              variant="outlined"
                              label="Nome"
                              type="text"
                              value={socio.nome}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4}>
                              <TextField
                                fullWidth
                                disabled
                                margin="normal"
                                variant="outlined"
                                label="CPF"
                                value={this.cpfMask(socio.cpf)}
                                defaultValue={socio.cpf}
                                type="text"
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2}>
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled
                                    checked={socio.principal}
                                  />
                                }
                                label="Principal"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Button
                              type="button"
                              fullWidth
                              onClick={event =>
                                this.removeCurrentSocio(event, key, socio.id)
                              }
                              color="danger"
                            >
                              <Delete />
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid container spacing={16}>
                          <Grid item xs={12} sm={12} md={4} lg={2}>
                            <Button
                              fullWidth
                              type="button"
                              onClick={event =>
                                this.toggleSocio(event, key, socio.id)
                              }
                              color="primary"
                            >
                              <Edit /> Editar Sócio
                          </Button>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
                  <Grid container direction="row" style={{marginTop: "2%"}}>
                    <Divider/>
                    <Typography
                        className={classes.dividerFullWidth}
                        color="textSecondary"
                        variant="caption"
                    >
                      Sócios
                    </Typography>
                  </Grid>
                  <Socios socios={this.state.socios} handleChange={this.handleChange} errors={this.state.errors} />
                  <Grid
                    container
                    direction="row"
                    style={{ marginTop: "2%" }}
                    spacing={16}
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <Button
                        type="button"
                        fullWidth
                        onClick={() => this.removeItem()}
                        color="danger"
                      >
                        <Delete /> Remover Sócio
                    </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <Button
                        fullWidth
                        type="button"
                        onClick={this.addItem}
                        color="success"
                      >
                        <AddIcon /> Adicionar Sócio
                    </Button>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" style={{ marginTop: "2%" }}>
                    <Divider />
                    <Typography
                      className={classes.dividerFullWidth}
                      color="textSecondary"
                      variant="caption"
                    >
                      CNAE's (Cadastro nacional de atividades)
                        </Typography>
                  </Grid>
                  <CNAES handleChange={this.handleChange} cnaes={this.state.cnaes} errors={this.state.errors} removeItemCnae={this.removeItemCnae} />

                  <Grid
                    container
                    direction="row"
                    style={{ marginTop: "2%" }}
                    spacing={16}
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={4} lg={2}>
                      <Button
                        fullWidth
                        type="button"
                        onClick={this.addItemCnae}
                        color="success"
                      >
                        <AddIcon /> Adicionar Cnae
                    </Button>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        required
                        name="id_atividade"
                        label="Atividade"
                        fullWidth
                        value={this.state.id_atividade}
                        onChange={this.handleChange}
                        error={this.state.errors.id_atividade}
                        helperText={this.state.errors.id_atividade}
                        margin="normal"
                        variant="outlined"
                      >
                        {this.state.atividade.map((option, key) => (
                          <MenuItem key={key} value={option.id}>
                            {option.nome}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        name="desc_atividade"
                        label="Descrição de atividade"
                        fullWidth
                        value={this.state.desc_atividade}
                        onChange={this.handleChange}
                        error={this.state.errors.descricao_atividade}
                        helperText={this.state.errors.descricao_atividade}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    {
                      (this.state.id_tipos_alvara !== 2 || this.state.id_taxas === 8)  &&  (
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-select-currency"
                            type="number"
                            name="area"
                            label="Metro (Área)"
                            fullWidth
                            value={this.state.area}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            error={this.state.errors.area_m2}
                            helperText={this.state.errors.area_m2}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">m²</InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                      )
                    }

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        required
                        name="id_isento"
                        label="Isento"
                        disabled={this.state.tipo_declaracao_id == 4 ? true : false}
                        fullWidth
                        value={this.state.id_isento}
                        error={this.state.errors.id_isento}
                        helperText={this.state.errors.id_isento}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      >
                        {this.state.isento.map((option, key) => (
                          <MenuItem key={key} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        type="time"
                        name="horario_inicio"
                        label="Horário Início"
                        fullWidth
                        value={moment(
                          this.state.horario_inicio,
                          "HH:mm"
                        ).format("HH:mm")}
                        error={this.state.errors.inicio_funcionamento}
                        helperText={this.state.errors.inicio_funcionamento}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        type="time"
                        name="horario_fim"
                        label="Horário Fim"
                        fullWidth
                        value={moment(this.state.horario_fim, "HH:mm").format(
                          "HH:mm"
                        )}
                        onChange={this.handleChange}
                        error={this.state.errors.fim_funcionamento}
                        helperText={this.state.errors.fim_funcionamento}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" style={{ marginTop: "2%" }}>
                    <Divider />
                    <Typography
                      className={classes.dividerFullWidth}
                      color="textSecondary"
                      variant="caption"
                    >
                      Dados do Estabelecimento
                    </Typography>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        name="nome_fantasia"
                        label="Nome Fantasia"
                        fullWidth
                        value={this.state.nome_fantasia}
                        onChange={this.handleChange}
                        error={this.state.errors.nome_fantasia}
                        helperText={this.state.errors.nome_fantasia}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FastSelect
                        name="id_imovel"
                        label="Imóvel"
                        onChange={this.handleChange}
                        value={this.state.id_imovel}
                        error={this.state.errors.imovel_id}
                        options={this.state.imovel.map(option => {
                          return {
                            value: option.id,
                            label: `Insc. Cart: ${
                              option.inscricao_cartografica
                              } || Bairro: ${option.bairro_id ? option.bairro.nome : ""} Logr.:${option
                                .logradouro_id ? option.logradouro.nome : ""}`
                          }
                        })}
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        name="logradouro"
                        label="Logradouro"
                        fullWidth
                        value={this.state.logradouro}
                        error={this.state.errors.logradouro}
                        helperText={this.state.errors.logradouro}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        name="bairro"
                        label="Bairro"
                        fullWidth
                        value={this.state.bairro}
                        onChange={this.handleChange}
                        error={this.state.errors.bairro}
                        helperText={this.state.errors.bairro}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        name="cidade"
                        label="Cidade"
                        fullWidth
                        value={this.state.cidade}
                        onChange={this.handleChange}
                        error={this.state.errors.cidade}
                        helperText={this.state.errors.cidade}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        name="cep"
                        label="CEP"
                        fullWidth
                        value={this.state.cep}
                        error={this.state.errors.cep}
                        helperText={this.state.errors.cep}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        name="uf"
                        label="UF"
                        fullWidth
                        value={this.state.uf}
                        onChange={this.handleChange}
                        error={this.state.errors.uf}
                        helperText={this.state.errors.uf}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
              {this.state.id_tipos_alvara && this.state.id_tipos_alvara === 2 && (
                <div>
                  <Grid container direction="row" style={{ marginTop: "2%" }}>
                    <Divider />
                    <Typography
                      className={classes.dividerFullWidth}
                      color="textSecondary"
                      variant="caption"
                    >
                      Alvará de Veículo
                    </Typography>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        required
                        name="id_utilizacao"
                        label="Utilização"
                        fullWidth
                        error={this.state.errors.utilizacao_id}
                        helperText={this.state.errors.utilizacao_id}
                        value={this.state.id_utilizacao}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      >
                        {this.state.utilizacao.map((option, key) => (
                          <MenuItem key={key} value={option.id}>
                            {option.nome}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        name="numero_da_vaga"
                        error={this.state.errors.numero_da_vaga}
                        helperText={this.state.errors.numero_da_vaga}
                        label="Nº da vaga"
                        fullWidth
                        value={this.state.numero_da_vaga}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-select-currency"
                        
                        name="chassi"
                        error={this.state.errors.chassi}
                        helperText={this.state.errors.chassi}
                        label="Chassi"
                        fullWidth
                        value={this.state.chassi}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextField
                        id="outlined-select-currency"
                        
                        name="placa"
                        label="Placa"
                        fullWidth
                        value={this.state.placa}
                        error={this.state.errors.placa}
                        helperText={this.state.errors.placa}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        type="number"
                        name="ano"
                        label="Ano"
                        fullWidth
                        error={this.state.errors.ano}
                        helperText={this.state.errors.ano}
                        value={this.state.ano}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        name="marca"
                        label="Marca"
                        fullWidth
                        value={this.state.marca}
                        error={this.state.errors.marca}
                        helperText={this.state.errors.marca}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        name="modelo"
                        label="Modelo"
                        error={this.state.errors.modelo}
                        helperText={this.state.errors.modelo}
                        fullWidth
                        value={this.state.modelo}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        name="cor"
                        label="Cor"
                        error={this.state.errors.cor}
                        helperText={this.state.errors.cor}
                        fullWidth
                        value={this.state.cor}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </CardBody>
            <CardFooter>
              <Grid container justify="center">
                <Grid item lg={2}>
                  <Button block color="info" round onClick={this.handleSave}>
                    <AddIcon />{" "}
                    {this.props.match.params.id ? "Atualizar" : "Adicionar"}
                  </Button>
                </Grid>
              </Grid>
            </CardFooter>
          </Card>
        </LoadingContent>

        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          style={{ zIndex: "3" }}
        >
          <DialogTitle id="form-dialog-title">Deseja emitir o DAM?</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose} color="danger">
              Cancelar
            </Button>
            <Button onClick={this.openModalDam} color="info">
              Emitir
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.modalDam}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          style={{ zIndex: "3" }}
        >
          <DialogTitle id="form-dialog-title">Emitir DAM</DialogTitle>
          <DialogContent>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputMask
                  mask="9999"
                  value={this.state.ano_dialog}
                  onChange={this.handleChange}
                >
                  {() => (
                    <TextField
                      id="outlined-name"
                      required
                      label="Ano"
                      name="ano_dialog"
                      helperText={this.state.errors.ano}
                      fullWidth
                      error={this.state.errors.ano}
                      value={this.state.ano_dialog}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputMask
                  mask="99"
                  value={this.state.mes_dialog}
                  onChange={this.handleChange}
                >
                  {() => (
                    <TextField
                      id="outlined-name"
                      label="Mês"
                      name="mes_dialog"
                      helperText={this.state.errors.mes}
                      fullWidth
                      error={this.state.errors.mes}
                      value={this.state.mes_dialog}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="date"
                  label="Data do Vencimento"
                  margin="normal"
                  required
                  variant="outlined"
                  type="date"
                  value={this.state.data_vencimento_dialog}
                  fullWidth
                  helperText={this.state.errors.vencimento}
                  error={this.state.errors.data_vencimento}
                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                  name="data_vencimento_dialog"
                  onChange={this.handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="date"
                  label="Data da Validade"
                  margin="normal"
                  variant="outlined"
                  type="date"
                  required
                  value={this.state.data_validade_dialog}
                  fullWidth
                  helperText={this.state.errors.data_validade}
                  error={this.state.errors.data_validade}
                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                  name="data_validade_dialog"
                  onChange={this.handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid> */}
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  label="Histórico do DAM"
                  margin="normal"
                  multiline
                  rowsMax={4}
                  variant="outlined"
                  fullWidth
                  helperText={this.state.errors.historico_dam}
                  error={this.state.errors.historico_dam}
                  name="historico_dam_dialog"
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <CardFooter>
              <Grid container justify="center">
                <Grid item lg={4}>
                  <Button
                    block
                    color="info"
                    round
                    onClick={this.postEmitirDam}
                  >
                    <AddIcon /> Gerar
                        </Button>
                </Grid>
              </Grid>
            </CardFooter>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Voltar
                    </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.modalCustom}
          onClose={this.handleCloseSocio}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth
        >
          <form onSubmit={this.handleSaveSocio}>
            <DialogTitle id="form-dialog-title">Atualizar Sócio</DialogTitle>
            <LoadingContent isLoading={this.state.isLoadingDialog}>
              <DialogContent>
                <Grid container spacing={16} alignItems="center">
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <TextField
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      label="Nome"
                      type="text"
                      name="nome"
                      value={this.state.nome}
                      onChange={this.handleChange}
                      error={this.state.errors.nome}
                      helperText={this.state.errors.nome}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <TextField
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      label="CPF"
                      type="text"
                      name="cpf"
                      value={this.cpfMask(this.state.cpf)}
                      onChange={this.handleChange}
                      error={this.state.errors.cpf}
                      helperText={this.state.errors.cpf}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            name="principal"
                            checked={
                              this.state.principal
                            }
                            onChange={this.handleChange}
                            value={true}
                          />
                        }
                        label="Principal"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </DialogContent>
            </LoadingContent>
            <DialogActions>
              <Button onClick={this.handleCloseSocio} color="danger">
                Cancelar
              </Button>
              <Button type="submit" color="success">
                Atualizar
              </Button>
            </DialogActions>
          </form>
        </Dialog>


      </div>
    )
  }
}

export default withStyles(logradouroStyle)(AlvaraForm)
