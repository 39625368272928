import axios from "axios";
import { defaultHeaders } from "./Constants";

export function getAll(callBack)
{
    let config = {
        headers: defaultHeaders()
      }
      let url = process.env.REACT_APP_API + "situacoesDivida/create"
      axios
        .get(url, config)
        .then(response => {
          callBack(undefined, response.data)
        })
        .catch(error => {
          callBack(error)
        })
}