import axios from "axios"
import { defaultHeaders } from "./Constants"

export function relatorioArrecadacao(inicial, final, receita,agente_recebedor_id, contribuinte, filter, modelo, type, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `relatoriosGerais/arrecadacaoPorReceita?data_inicial=${inicial ? inicial : ""}&type=${type}&data_final=${final ? final : ""}&receita_id=${receita ? receita : ""}&contribuinte_id=${contribuinte ? contribuinte : ""}&filter_by=${filter ? filter : ""}&modelo=${modelo ? modelo : ""}&agente_recebedor_id=${agente_recebedor_id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function relatorioContabilidade(inicial, final, receita,agente_recebedor_id, filter, type, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `relatoriosGerais/arrecadacaocontabilidade?data_inicial=${inicial ? inicial : ""}&type=${type}&data_final=${final ? final : ""}&receita_id=${receita ? receita : ""}&filter_by=${filter ? filter : ""}&agente_recebedor_id=${agente_recebedor_id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getFiltros(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `relatoriosGerais/filtros`
  axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
}


export function relatorioLog(inicial, final, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `relatorios/atividades?data_inicial=${inicial ? inicial : ""}&data_final=${final ? final : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
