import React, {Component} from 'react'
import {demonstrativoDeCalculoDeIPTU} from '../../../util/imovel'
import {showNotification} from '../../../util/Constants';
import LoadingContent from '../../../components/LoadingContent';
import {Dialog, DialogTitle, DialogContent, Grid, Button, DialogActions} from '@material-ui/core';
import Print from "@material-ui/icons/Print"
import CardBody from '../../../components/Card/CardBody';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardFooter from '../../../components/Card/CardFooter';
import numeral from "../../../util/numeral"

class DemonstrativoModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            demonstrativo: props.demonstrativo,

        }
        if (props.hasOwnProperty("REF") != undefined) {
            props.a(this)
        }
    }

    imprimirDialogo = () => {
        console.log(this.state)
        let dem = this.state.demonstrativo
        dem.imovel = this.state.imovel
        let obj = JSON.stringify(dem)
        localStorage.setItem("doc", obj)
        window.open("/templateImovel")
    }

    render() {
        if (this.state.demonstrativo == null) {
            return <Button></Button>
        }
        const {
            fatores_corretivos_de_edificacao,
            fatores_corretivos_de_terreno,
            quantidade_de_fatores_terreno,
            quantidade_de_fatores_edificacao,
            soma_fatores_edificacao,
            soma_fatores_terreno,
            fator_corretivo_de_edificacao,
            fator_corretivo_de_terreno,
            valor_declarado,
            valor_venal_de_edificacao,
            valor_venal_de_terreno,
            valor_m2_edificacao,
            valor_venal,
            aliquota,
            valor_m2_terreno
        } = this.state.demonstrativo

        return (<Dialog
            open={this.props.show || false}
            onClose={e => this.props.toggle}
            fullWidth
            maxWidth="lg"
            aria-labelledby="form-dialog-title"
            id="teste"
        >
            <DialogTitle id="form-dialog-title">
                Demonstrativo de cálculo de IPTU
            </DialogTitle>
            <DialogContent>

                <Card>
                    <CardBody>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <span>Inscrição: {this.state.imovel.id}</span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <span>Inscrição Cartográfica: {this.state.imovel.inscricao_cartografica}</span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <span>Nome/Razão Social do Contribuinte: {this.state.imovel.contribuinte.nome_razao_social}</span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <span>Número: {this.state.imovel.numero}</span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <span>Complemento: {this.state.imovel.complemento}</span>
                            </Grid>
                        </Grid>
                    </CardBody>
                </Card>
                <LoadingContent isLoading={Object.keys(fatores_corretivos_de_edificacao).length === 0}>
                    <Card>
                        <CardHeader>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h3 className="text-center"> Fatores corretivos de edificação</h3>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                {Object.keys(fatores_corretivos_de_edificacao).map((fator, key) => (
                                    <Grid key={key} item xs={12} sm={12} md={3} lg={3}>
                                        <Grid container>
                                            <span><strong className="text-black">{fator}</strong></span>
                                        </Grid>
                                        <Grid container>
                                            <small>{fatores_corretivos_de_edificacao[fator]}</small>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <span><strong
                                        className="text-black">Quantidade de fatores:</strong> </span><small>{quantidade_de_fatores_edificacao}</small>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <span><strong
                                        className="text-black">Soma dos fatores:</strong> </span><small>{soma_fatores_edificacao}</small>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <span><strong
                                        className="text-black">Fator corretivo (Soma/Quantidade):</strong> </span><small>{fator_corretivo_de_edificacao}</small>
                                </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h3 className="text-center"> Fatores corretivos de Terreno</h3>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                {Object.keys(fatores_corretivos_de_terreno).map((fator, key) => (

                                    <Grid key={key} item xs={12} sm={12} md={3} lg={3}>
                                        <Grid container>
                                            <span><strong className="text-black">{fator}</strong></span>
                                        </Grid>
                                        <Grid container>
                                            <small>{fatores_corretivos_de_terreno[fator]}</small>
                                        </Grid>
                                    </Grid>

                                ))}
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <span><strong
                                        className="text-black">Quantidade de fatores:</strong> </span><small>{quantidade_de_fatores_terreno}</small>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <span><strong
                                        className="text-black">Soma dos fatores:</strong> </span><small>{soma_fatores_terreno}</small>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <span><strong
                                        className="text-black">Fator corretivo (Soma/Quantidade):</strong> </span><small>{fator_corretivo_de_terreno}</small>
                                </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h3 className="text-center">Valores Declarado</h3>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            {/* <Grid container>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Grid container><strong className="text-black">Valor do metro quadrado de terreno
                                        (Baseado em seção de logradouro):</strong></Grid>
                                    <Grid container>
                                        <span className="text-center">{numeral(parseFloat(valor_m2_terreno))
                                            .format("0.0,00")
                                            .slice(0, -2)}</span>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Grid container><strong className="text-black">Valor do metro quadrado de edificação
                                        (Baseado em classificação arquitetônica):</strong>
                                    </Grid>
                                    <Grid container><span>{numeral(parseFloat(valor_m2_edificacao))
                                        .format("0.0,00")
                                        .slice(0, -2)}</span></Grid>

                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Grid container><strong className="text-black">Valor venal do terreno (Área
                                        edificacada x Valor do m² x Fator corretivo de edificação):</strong></Grid>
                                    <Grid container><span>{numeral(parseFloat(valor_venal_de_terreno))
                                        .format("0.0,00")
                                        .slice(0, -2)}</span></Grid>

                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Grid container><strong className="text-black">Valor venal da edifificação (Área
                                        edificacada x Valor do m² x Fator corretivo de edificação):</strong></Grid>
                                    <Grid container> <span>{numeral(parseFloat(valor_venal_de_edificacao))
                                        .format("0.0,00")
                                        .slice(0, -2)}</span></Grid>

                                </Grid>
                            </Grid> */}
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h2 className="text-center">Valores Declarado</h2>
                                    <h2 className="text-center">{numeral(parseFloat(valor_declarado))
                                        .format("0.0,00")
                                        .slice(0, -2)}</h2>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h2 className="text-center">Alíquota : {numeral(parseFloat(aliquota))
                                        .format("0.0,00")
                                        .slice(0, -2)} %</h2>
                                    <h2 className="text-center">Valor do imposto :
                                        R${numeral(((parseFloat(aliquota) * parseFloat(valor_declarado)) / 100))
                                            .format("0.0,00")
                                            .slice(0, -2)}</h2>
                                </Grid>
                            </Grid>
                        </CardBody>
                    </Card>
                </LoadingContent>
            </DialogContent>
            <DialogActions classes={{justifyContent: 'center'}}>
                <Button
                    outlined
                    onClick={e => this.props.toggle()}
                    color="secondary"
                >
                    Fechar
                </Button>
                <Button outlined color="primary" onClick={this.imprimirDialogo} target="_blank"><Print/> Imprimir
                </Button>
            </DialogActions>
        </Dialog>)
    }
}


export default DemonstrativoModal