import React from "react"
import {withRouter} from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import {carne} from "../../../util/imovel"
import AsyncSelect from "../../../components/AsyncSelect"
import {search as searchImovel} from "../../../util/imovel"
import {showNotification, mapErrors} from "../../../util/Constants"
import numeral from "../../../util/numeral"
import {MenuItem, withStyles, DialogActions} from "@material-ui/core"
import Card from "../../../components/Card/Card"
import CardBody from "../../../components/Card/CardBody"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import Button from "../../../components/CustomButtons/Button"
import {showWaiting, hideWaiting} from "../../../util/Constants"
import Warning from "@material-ui/icons/Warning"
import {put} from "../../../util/parcelaIptu"

import moment from "moment"
import CardFooter from "../../../components/Card/CardFooter"
import SearchFields from "../../../components/SearchFields"
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import TableCell from "@material-ui/core/TableCell";
import {me} from "../../../util/authApi";

class AlterarVencimentoParcela extends React.Component {
    constructor(props) {
        super(props)
        let initialState = {
            imoveis: [],
            id_imovel: 0,
            errors: {},
            lancamentos: [],
            id_lancamentos: 0,
            parcelas: [],
            id_parcelas: 0,
            data_vencimentoAntiga: "",
            data_vencimentoNova: moment(new Date()).format("YYYY-MM-DD"),
            modal: false,
            semMulta: false,
            semJuros: false,
            podeAlterarMultaOuJuros: false
        }
        if (props.fromModal) {
            initialState.lancamentos = [props.lancamento]
            initialState.id_lancamentos = props.lancamento.id
            initialState.parcelas = props.lancamento.parcelas
        }
        this.state = initialState
        this.loadImovel = this.loadImovel.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getImovelById = this.getImovelById.bind(this)
        this.getParcelaLancById = this.getParcelaLancById.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.getParcela = this.getParcela.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.getCarne = this.getCarne.bind(this)
        this.componentDidMount = this.componentDidMount.bind(this)
    }

    handleOpen() {
        this.setState({
            modal: true
        })
    }

    componentDidMount() {
        me((error, data) => {
            var podeAlterar = false;
            if (data) {
                    if (data.id == 5  || data.id == 1) {
                        podeAlterar = true
                    }
            }
            this.setState({podeAlterarMultaOuJuros: podeAlterar}, () => {
                console.log(this.state.podeAlterarMultaOuJuros)
            })
        })


    }

    handleClose() {
        this.setState({
            modal: false
        }, () => {
            this.props.history.push("/iptu/movimentos")
        })
    }

    onClickMulta = () => {
        this.setState({semMulta: !this.state.semMulta})
    }
    onClickJuros = () => {
        this.setState({semJuros: !this.state.semJuros})
    }

    getCarne(index) {
        showWaiting()
        carne(
            this.state.id_imovel.value,
            "",
            "",
            "",
            (error, data) => {
                hideWaiting()
                if (error) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        try {
                            let error = JSON.parse(text)
                            let status = error.status
                            if (status === "pago" || status === "vencido" || status === "isento") {
                                showNotification("top", "center", error.message, "warning")
                            }
                        } catch (e) {
                            showNotification(
                                "top",
                                "center",
                                "Ocorreram erros, verifique sua conexão com a internet",
                                "danger"
                            )
                        }
                    })

                    reader.readAsText(error.response.data)
                } else {
                    if (index !== undefined) {
                        var url = URL.createObjectURL(data)
                        let tempLink = document.createElement("a")
                        tempLink.setAttribute("target", "_blank")
                        tempLink.setAttribute("href", `${url}`)
                        tempLink.setAttribute("style", "display:none;")
                        document.body.appendChild(tempLink)
                        tempLink.click()
                        document.body.removeChild(tempLink)
                    } else {
                        const reader = new FileReader()
                        reader.addEventListener("loadend", e => {
                            const text = e.srcElement.result
                            let json = JSON.parse(text)
                            showNotification(
                                "top",
                                "center",
                                `${json.message}`,
                                "success"
                            )
                        })
                        reader.readAsText(data)
                        this.handleClose()
                    }
                }
            }
        )
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        errors[name] = undefined
        this.setState(
            {
                [name]: event.target.value,
                errors: errors
            },
            () => {
                if (this.state.id_imovel && name === "id_imovel") {
                    let imovel = this.getImovelById(this.state.id_imovel.value)
                    this.setState({
                        lancamentos: imovel.lancamentos
                    })
                }
                if (this.state.id_lancamentos && name === "id_lancamentos") {
                    let parcelaLancamento = this.getParcelaLancById(
                        this.state.id_lancamentos
                    )
                    this.setState({
                        parcelas: parcelaLancamento.parcelas.filter(parcela => parcela.tipo_parcela.ordem !== 0),
                        lancadoNaDivida: parcelaLancamento.lancado_na_divida,
                        divida_id: parcelaLancamento.divida_id
                    })
                }
                if (this.state.id_parcelas && name === "id_parcelas") {
                    let parcela = this.getParcela(this.state.id_parcelas)
                    this.setState({
                        data_vencimentoAntiga: parcela.vencimento
                    })
                }
            }
        )
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let params = {
            vencimento: this.state.data_vencimentoNova,
            sem_multa: this.state.semMulta,
            sem_juros: this.state.semJuros
        }
        let response = (error, data) => {
            hideWaiting()

            if (error) {
                let errors = error.response.data.errors
                if (errors) {
                    Object.keys(errors).map(key => {
                       errors[key].map(mensagem => {
                            showNotification(
                                "top",
                                "center",
                                 mensagem,
                                "danger"
                            )
                        })
                    })

                    this.setState({
                        errors: mapErrors(error)
                    })
                } else if (error.response.data.message) {
                    showNotification(
                        "top",
                        "center",
                        `Ocorreram erros! ${error.response.data.message}`,
                        "danger"
                    )
                }
            } else {
                showNotification(
                    "top",
                    "center",
                    `Data de vencimento alterada realizada com sucesso`,
                    "success"
                )

                if (this.props.fromModal) {
                    this.props.handleClose("success")
                } else {
                    this.handleOpen()
                    //this.props.history.push("/iptu/movimentos")
                }
            }
        }
        put(this.state.id_parcelas, params, response)
    }

    loadImovel(inputValue, callback) {
        searchImovel(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let imoveis = data.data.map(imovel => {
                        console.log(imovel)
                        return {
                            value: imovel.id,
                            label: `Insc: ${imovel.id} Cart.: ${imovel.inscricao_cartografica} Logr.: ${imovel.logradouro == null ? "" : imovel.logradouro.nome} Nº: ${imovel.numero}`
                        }
                    })
                    this.setState({
                        imoveis: data.data
                    })
                    callback(imoveis)
                }
            }
        )
    }


    handleselectedFile = event => {
        this.setState({
            arquivo_de_baixa: event.target.files[0]
        })
    }

    getImovelById(id) {
        return this.state.imoveis.filter(item => {
            return id === item.id
        })[0]
    }

    getParcelaLancById(id) {
        return this.state.lancamentos.filter(item => {
            return id === item.id
        })[0]
    }

    getParcela(id) {
        return this.state.parcelas.filter(item => {
            return id === item.id
        })[0]
    }

    render() {
        const fields = [
            {value: "", label: "Todos os campos"},
            {value: "id", label: "Inscrição"},
            {value: "inscricao_cartografica", label: "Inscrição Cartográfica"},
            {value: "contribuinte_id", label: "Código do contribuinte"},
            {
                value: "contribuinte_nome_razao_social",
                label: "Nome/Razão Social do Contribuinte"
            },
            {value: "contribuinte_cpf_cnpj", label: "Documento do Contribuinte"},
            {value: "bairro", label: "Bairro"},
            {value: "contribuinte_endereco", label: "Endereço do Contribuinte"},
            {value: "logradouro_id", label: "Código do logradouro"},
            {value: "secao_id", label: "Código da Seção"},
            {value: "logradouro", label: "Logradouro"},
            {value: "complemento", label: "Complemento"},
            {value: "quadra", label: "Quadra"},
            {value: "lote", label: "Lote"},
            {value: "loteamento", label: "Loteamento"}
        ]

        const {classes} = this.props
        return (
            <div className={classes.container}>
                <Card>
                    <CardBody>
                        <Typography color="textSecondary" variant="caption">
                            Altere a data de vencimento de parcelas de lançamentos
                        </Typography>
                        {!this.props.fromModal && (
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <AsyncSelect
                                        className={
                                            `form-control form-control-alternative form-select Select-menu-outer ${
                                                this.props.error ? "has-danger" : ""
                                            }`
                                        }
                                        value={this.state.id_imovel}
                                        name="id_imovel"
                                        onChange={this.handleChange}
                                        loadOptions={this.loadImovel}
                                        placeholder="Imóvel"
                                        message="Pesquise o Imóvel"
                                    />
                                    <SearchFields
                                        name="filterField"
                                        value={this.state.filterField || ""}
                                        onChangeFields={this.handleChange}
                                        fields={fields}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        <Grid container direction="row" spacing={16}>
                            {(this.state.id_imovel !== 0 || this.props.fromModal) && (
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        required
                                        name="id_lancamentos"
                                        label="Lançamento"
                                        fullWidth
                                        value={this.state.id_lancamentos}
                                        error={this.state.errors.id_lancamentos}
                                        helperText={this.state.errors.id_lancamentos}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        {this.state.lancamentos.map((option, key) => (
                                            <MenuItem key={key} value={option.id}>
                                                Ano: {option.ano} Valor IPTU: R$
                                                {numeral(parseFloat(option.valor_iptu))
                                                    .format("0.0,00")
                                                    .slice(0, -2)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            )}

                            {this.state.parcelas.length > 0 && (
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        required
                                        name="id_parcelas"
                                        label="Parcelas"
                                        fullWidth
                                        value={this.state.id_parcelas}
                                        error={this.state.errors.id_parcelas}
                                        helperText={this.state.errors.id_parcelas}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        {this.state.parcelas.map((option, key) => (
                                            <MenuItem key={key} value={option.id}>
                                                {option.tipo_parcela.descricao} - R${option.valor}{" "}
                                                {option.vencido ? " - Vencida" : ""}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            )}
                        </Grid>
                        {this.state.id_parcelas !== 0 && (

                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="date"
                                        label="Data do Vencimento"
                                        margin="normal"
                                        disabled
                                        variant="outlined"
                                        type="date"
                                        value={this.state.data_vencimentoAntiga}
                                        fullWidth
                                        helperText={this.state.errors.data_vencimentoAntiga}
                                        error={this.state.errors.data_vencimentoAntiga}
                                        name="data_vencimentoAntiga"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="date"
                                        label="Nova Data de Vencimento"
                                        margin="normal"
                                        variant="outlined"
                                        type="date"
                                        fullWidth
                                        value={this.state.data_vencimentoNova}
                                        helperText={this.state.errors.vencimento}
                                        error={this.state.errors.vencimento}
                                        name="data_vencimentoNova"
                                        onChange={this.handleChange}
                                    />
                                </Grid>{
                                this.state.podeAlterarMultaOuJuros ? <div className={'row'}>
                                    <div className={'col-6'} style={{width:150}}>
                                        <fieldset style={{border:'1px solid #bdbdbd',borderRadius: 4, marginLeft: 10}}>
                                            <legend style={{fontSize:12,color:'#787878',padding:2,width:'auto'}}>Sem Multa</legend>
                                        <Checkbox
                                            checked={this.state.semMulta}
                                            tabIndex={-1}
                                            onClick={this.onClickMulta}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.root
                                            }}
                                        />
                                        </fieldset>
                                    </div>
                                    <div className={'col-6'} style={{width:150}}>
                                        <fieldset style={{border:'1px solid #bdbdbd',borderRadius: 4, marginLeft: 10}}>
                                            <legend style={{fontSize:12,color:'#787878',padding:2,width:'auto'}}>Sem Juros</legend>
                                        <Checkbox
                                            checked={this.state.semJuros}
                                            tabIndex={-1}
                                            onClick={this.onClickJuros}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.root
                                            }}
                                        />
                                        </fieldset>
                                    </div>
                                </div> : null}
                            </Grid>
                        )}
                    </CardBody>
                    <CardFooter>
                        <Grid container justify="center">
                            <Grid item lg={this.state.lancadoNaDivida ? 6 : 2}>
                                <Button
                                    block
                                    color={this.state.lancadoNaDivida ? "danger" : "info"}
                                    disabled={this.state.lancadoNaDivida || this.state.imoveis.length === 0}
                                    round
                                    onClick={this.handleSave}
                                >
                                    {this.state.lancadoNaDivida ? (
                                        <div>
                                            <div>
                                                <Warning/> O Lançamento Selecionado foi lançado na
                                                dívida
                                            </div>
                                            <div>Código da dívida ativa: {this.state.divida_id}</div>
                                        </div>
                                    ) : (
                                        <div>
                                            <AddIcon/> Alterar
                                        </div>
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>

                <Dialog
                    open={this.state.modal}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    style={{zIndex: "3"}}
                >
                    <DialogTitle id="form-dialog-title">Deseja imprimir o carnê de IPTU?</DialogTitle>
                    <DialogActions>
                        <Button block color="danger" round onClick={this.handleClose}>
                            Cancelar
                        </Button>
                        <Button block color="success" round onClick={this.getCarne}>
                            Imprimir
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

AlterarVencimentoParcela = withRouter(AlterarVencimentoParcela)
export default withStyles(secaoStyle)(AlterarVencimentoParcela)
