import React from "react"
import { post, edit, getField, put, imprimir, calcularMultaJurosRequest } from "../../util/damavulso"
import {
    showNotification,
    removeMask,
    mapErrors,
    showWaiting,
    hideWaiting
} from "../../util/Constants"
import DecimalFormatCustom from "../../components/DecimalFormatCustom"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import Button from "../../components/CustomButtons/Button"
import secaoStyle from "../../assets/css/layout/secaoStyle"
import { search as searchContribuinte } from "../../util/contribuinte"
import { withStyles } from "@material-ui/core"
import NumberFormatCustom from "../../components/NumberFormatCustom"
import LoadingContent from "../../components/LoadingContent"
import InputMask from "react-input-mask"
import AsyncSelect from "../../components/AsyncSelect"
import SearchFields from "../../components/SearchFields"
import moment from "moment"
import SelectCustom from "../../components/SelectCustom"

class DamAvulsoForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            receitas: [],
            receita_id: "",
            competencia: "",
            valor: 0,
            desconto: 0,
            atualizacao_monetaria: 0,
            juros: 0,
            multa: 0,
            vencimento: moment().format("YYYY-MM-DD"),
            data_debito: moment().format("YYYY-MM-DD"),
            contribuinte_id: [],
            inscricao: "",
            historico: "",
            mensagem: "",
            numero_da_parcela: 0,
            quantidade_de_parcelas: 0,
        }
        this.toggleLoading = this.toggleLoading.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.imprimirDam = this.imprimirDam.bind(this)
        this.getData = this.getData.bind(this)
        this.calculaMultaJuros = this.calculaMultaJuros.bind(this)
    }

    handleChange(event) {
        let name = event.target.name
        let value = event.target.value
        this.setState(state => ({
            [name]: value,
            errors: { ...state.errors, [name]: undefined }
        }))
    }

    calculaMultaJuros(event) {
        if (this.state.valor && this.state.vencimento && this.state.data_debito) {
            if (this.state.vencimento !== this.state.data_debito) {
                const params = {
                    receita_id: this.state.receita_id.value,
                    valor: this.state.valor,
                    desconto: this.state.desconto,
                    atualizacao_monetaria: this.state.atualizacao_monetaria,
                    vencimento: this.state.vencimento,
                    data_deposito: this.state.data_debito
                }
                const response = async (error, data) => {
                    this.toggleLoading()
                    if (error) {
                        showNotification(
                            "top",
                            "center",
                            "Ocorreram erros, verifique o formulário e tente novamente",
                            "danger"
                        )
                        this.setState({
                            errors: mapErrors(error)
                        })
                    } else {
                        await this.setState(data)
                    }
                    this.toggleLoading()
                }
                calcularMultaJurosRequest(params, response)
            }
        }
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let params = {
            receita_id: this.state.receita_id.value,
            competencia: removeMask(this.state.competencia),
            valor: this.state.valor,
            desconto: this.state.desconto,
            atualizacao_monetaria: this.state.atualizacao_monetaria,
            juros: this.state.juros,
            multa: this.state.multa,
            vencimento: moment(this.state.vencimento).format(
                "YYYY-MM-DD"
            ),
            data_debito: moment(this.state.data_debito).format(
                "YYYY-MM-DD"
            ),
            contribuinte_id: this.state.contribuinte_id.value,
            inscricao: this.state.inscricao,
            historico: this.state.historico,
            mensagem: this.state.mensagem,
            quantidade_de_parcelas: this.state.quantidade_de_parcelas,
            numero_da_parcela: this.state.numero_da_parcela
        }
        let response = (error, data) => {
            if (error) {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                this.setState({
                    errors: mapErrors(error)
                })
            } else {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    `DAM Avulso ${this.props.match.params.id ? "editado" : "incluído"} com sucesso`,
                    "success"
                )
                this.imprimirDam(data.id)

            }
        }
        this.props.match.params.id
            ? put(this.props.match.params.id, params, response)
            : post(params, response)
    }

    imprimirDam(id) {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    let error = JSON.parse(text)
                    let status = error.status
                    showNotification(
                        "top",
                        "center",
                        `Ocorreram erros! ${error.message}`,
                        "danger"
                    )
                })
                reader.readAsText(error.response.data)

            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
                this.props.history.push("/damavulso/cadastro")
            }
        }
        imprimir(id, response)
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                                ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })
                    callback(contribuintes)
                }
            }
        )
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    componentWillMount() {
        if (this.props.location.state) {
            this.setState({
                receita_id: {
                    value: this.props.location.state.copy.receita_id, label: `Nº Receita: ${this.props.location.state.copy.receita.id} - ${this.props.location.state.copy.receita.nome}`
                },
                competencia: this.props.location.state.copy.competencia,
                valor: this.props.location.state.copy.valor,
                desconto: this.props.location.state.copy.desconto,
                atualizacao_monetaria: this.props.location.state.copy.atualizacao_monetaria,
                juros: this.props.location.state.copy.juros,
                multa: this.props.location.state.copy.multa,
                vencimento: this.props.location.state.copy.vencimento,
                data_debito: this.props.location.state.copy.data_debito,
                contribuinte_id: { value: this.props.location.state.copy.contribuinte_id, label: this.props.location.state.copy.contribuinte.nome_razao_social },
                inscricao: this.props.location.state.copy.inscricao,
                historico: this.props.location.state.copy.historico,
                mensagem: this.props.location.state.copy.mensagem,
                numero_da_parcela: this.props.location.state.copy.numero_da_parcela,
                quantidade_de_parcelas: this.props.location.state.copy.quantidade_de_parcelas
            })
        } else {
            this.getData()
        }
    }

    getData() {
        let response = (error, data) => {
            this.toggleLoading()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                let rec = data.receitas.map(receita => {
                    return {
                        value: receita.id,
                        label: `Nº Receita: ${receita.id} - ${receita.nome}`
                    }
                })
                this.setState({
                    receitas: rec,
                })
                if (this.props.match.params.id) {
                    data.dam.contribuinte_id = {
                        value: data.dam.contribuinte.id,
                        label: data.dam.contribuinte.nome_razao_social
                    }
                    let receita = rec.filter(receita => receita.value === data.dam.receita_id)
                    data.dam.receita_id = {
                        value: receita[0].value,
                        label: receita[0].label
                    }
                    this.setState(data.dam)
                }
                this.toggleLoading()
            }
        }
        if (this.props.match.params.id) {
            edit(this.props.match.params.id, response)
        } else {
            getField(response)
        }
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ]
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <Card>
                        <CardHeader color="primary">
                            <Grid container direction="row">
                                <Grid item lg={10} xs={12}>
                                    <h2 className={classes.cardTitleWhite}>DAM Avulso</h2>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <SelectCustom
                                        value={this.state.receita_id}
                                        name="receita_id"
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.receita_id}
                                        helperText={this.state.errors.receita_id}
                                        options={this.state.receitas}
                                        placeholder="Receita"
                                        message="Pesquise a Receita"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <InputMask
                                        mask="9999/99"
                                        value={this.state.competencia}
                                        onChange={this.handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                id="outlined-name"
                                                label="Competência"
                                                name="competencia"
                                                helperText={this.state.errors.competencia}
                                                fullWidth
                                                error={this.state.errors.competencia}
                                                value={this.state.competencia}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                            </Grid>
                            <Grid container spacing={16} direction="row">
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        name="valor"
                                        label="Valor"
                                        fullWidth
                                        value={this.state.valor}
                                        error={this.state.errors.valor}
                                        helperText={this.state.errors.valor}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        defaultValue={this.state.valor}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            inputComponent: DecimalFormatCustom
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="desconto"
                                        label="Desconto"
                                        fullWidth
                                        value={this.state.desconto}
                                        error={this.state.errors.desconto}
                                        helperText={this.state.errors.desconto}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        defaultValue={this.state.desconto}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            inputComponent: DecimalFormatCustom
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="atualizacao_monetaria"
                                        label="Atualização Monetária"
                                        fullWidth
                                        value={this.state.atualizacao_monetaria}
                                        error={this.state.errors.atualizacao_monetaria}
                                        helperText={this.state.errors.atualizacao_monetaria}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        defaultValue={this.state.atualizacao_monetaria}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            inputComponent: DecimalFormatCustom
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={16} direction="row">
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="juros"
                                        label="Juros"
                                        fullWidth
                                        value={this.state.juros}
                                        error={this.state.errors.juros}
                                        helperText={this.state.errors.juros}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        defaultValue={this.state.juros}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            inputComponent: DecimalFormatCustom
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="multa"
                                        label="Multa"
                                        fullWidth
                                        value={this.state.multa}
                                        error={this.state.errors.multa}
                                        helperText={this.state.errors.multa}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        defaultValue={this.state.multa}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            inputComponent: DecimalFormatCustom
                                        }}
                                    />
                                </Grid>
                                <Grid container spacing={16} direction="row"></Grid>
                                {
                                    (this.state.receita_id || {}).value !== 200 && (
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <TextField
                                                id="outlined-select-currency"
                                                name="data_debito"
                                                label="Data de Débito"
                                                fullWidth
                                                value={moment(this.state.data_debito).format("YYYY-MM-DD")}
                                                type="date"
                                                error={this.state.errors.data_debito}
                                                helperText={this.state.errors.data_debito}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                defaultValue={moment(this.state.data_debito).format(
                                                    "YYYY-MM-DD"
                                                )}
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    (this.state.receita_id || {}).value !== 200 && (
                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                            <TextField
                                                id="outlined-select-currency"
                                                name="vencimento"
                                                label="Data de Vencimento"
                                                fullWidth
                                                value={moment(this.state.vencimento).format("YYYY-MM-DD")}
                                                type="date"
                                                error={this.state.errors.vencimento}
                                                helperText={this.state.errors.vencimento}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                defaultValue={moment(this.state.vencimento).format(
                                                    "YYYY-MM-DD"
                                                )}
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    (this.state.receita_id || {}).value !== 200 && this.state.vencimento !== this.state.data_debito && (
                                        <Grid style={{ alignSelf: 'center' }} item xs={12} sm={12} md={4} lg={4} >
                                            <Button block color="warning" round onClick={this.calculaMultaJuros}>
                                                <AddIcon />{" "}
                                                {"Calcular"}
                                            </Button>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <Grid container spacing={16} direction="row">
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="quantidade_de_parcelas"
                                        label="Quantidade de parcelas"
                                        fullWidth
                                        type="number"
                                        value={this.state.quantidade_de_parcelas}
                                        error={this.state.errors.quantidade_de_parcelas}
                                        helperText={this.state.errors.quantidade_de_parcelas}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="numero_da_parcela"
                                        label="Nº da parcela"
                                        fullWidth
                                        type="number"
                                        value={this.state.numero_da_parcela}
                                        error={this.state.errors.numero_da_parcela}
                                        helperText={this.state.errors.numero_da_parcela}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SearchFields
                                        name="filterField"
                                        value={this.state.filterField || ""}
                                        onChangeFields={this.handleChange}
                                        fields={fields}
                                    />
                                    <Typography color="textSecondary" variant="caption">
                                        Pesquise o Contribuinte
                                    </Typography>
                                    <AsyncSelect
                                        className={
                                            `form-control form-control-alternative form-select Select-menu-outer ${
                                            this.props.error ? "has-danger" : ""
                                            }`
                                        }
                                        value={this.state.contribuinte_id}
                                        onChange={this.handleChange}
                                        loadOptions={this.loadOptions}
                                        error={this.state.errors.contribuinte_id}
                                        helperText={this.state.errors.contribuinte_id}
                                        name="contribuinte_id"
                                        placeholder="Contribuinte"
                                        message="Pesquise o contribuinte"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="inscricao"
                                        label="Inscrição"
                                        fullWidth
                                        type="number"
                                        value={this.state.inscricao}
                                        error={this.state.errors.inscricao}
                                        helperText={this.state.errors.inscricao}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        defaultValue={this.state.inscricao}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="mensagem"
                                        label="Mensagem"
                                        fullWidth
                                        value={this.state.mensagem}
                                        error={this.state.errors.mensagem}
                                        helperText={this.state.errors.mensagem}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        defaultValue={this.state.mensagem}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        label="Histórico"
                                        margin="normal"
                                        multiline
                                        rowsMax={4}
                                        variant="outlined"
                                        fullWidth
                                        value={this.state.historico}
                                        helperText={this.state.errors.historico}
                                        error={this.state.errors.historico}
                                        name="historico"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container justify="center">
                                <Grid item lg={2}>
                                    <Button block color="info" round onClick={this.handleSave}>
                                        <AddIcon />{" "}
                                        {this.props.match.params.id ? "Atualizar" : "Adicionar"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                </LoadingContent>
            </div>
        )
    }
}

export default withStyles(secaoStyle)(DamAvulsoForm)
